<template>
  <product-list :props="props" />
</template>

<script>
import ProductList from './ProductList.vue'
export default {
  data: function() {
    return {
      props: {
        productUrl: '',
        tagType: null,
        searchInfo: null,
      }
    }
  },
  components: {
    'product-list': ProductList,
  },
  created: function() {
    let motoBrandId = this.$route.query.motoBrandId;
    this.$api.get(`/get_moto_brand_detail?id=${motoBrandId}`)
      .then(data => {
        this.props = {
          productUrl: `/query_product_by_moto_brand?moto_brand_id=${motoBrandId}`,
          tagUrl: `/query_tag_list_by_moto_brand?moto_brand_id=${motoBrandId}`,
          brandUrl: `/query_product_brand_by_moto_brand?moto_brand_id=${motoBrandId}`,
          tagType: 0,
          searchInfo: `${data.data.name}`,
        };
      });
  },
  beforeRouteUpdate: function(to, from, next) {
    let motoBrandId = to.query.motoBrandId;
    this.$api.get(`/get_moto_brand_detail?id=${motoBrandId}`)
      .then(data => {
        this.props = {
          productUrl: `/query_product_by_moto_brand?moto_brand_id=${motoBrandId}`,
          tagUrl: `/query_tag_list_by_moto_brand?moto_brand_id=${motoBrandId}`,
          brandUrl: `/query_product_brand_by_moto_brand?moto_brand_id=${motoBrandId}`,
          tagType: 0,
          searchInfo: `${data.data.name}`,
        };
      });
  }
}
</script>

<style scoped>
</style>
