<template>
  <div class="searchContainer">
    <input class="wechatSearchInput" type="text" v-model="searchKeyword" @keydown.enter="search()" placeholder="输入商品名称或配件编号" />
    <btn-orange :click="search"
                text="搜索"
                class="search"/>
    <a style="font-size: 16px;" class="link" @click="linkToSearchByBike()">按车型搜索</a>
  </div>
</template>

<script>
  import ButtonOrange from "../components/mobile/ButtonOrange.vue";

  export default {
    components: {
      'btn-orange':ButtonOrange,
    },
    props: {
      searchCallBack: {
        type: Function,
        default: ()=>{}
      },
      linkCallBack: {
        type: Function,
        default: function() {
          this.$router.push({
            name:'SearchByBikeForWechat'
          });
        }
      },
    },
    data: function() {
      return {
        searchKeyword: '',
        brands: '',
      }
    },
    methods: {
      search() {
        if (this.searchKeyword.length == 0)
          return;
        this.$router.push({
          name: 'productListBySearch',
          query: { keyword: this.searchKeyword, brands: this.brands },
        });
        this.searchCallBack();
      },
      linkToSearchByBike() {
        this.linkCallBack();
      }
    },
  }
</script>

<style scoped>
  .searchContainer {
    margin: 0 auto;
    padding: 24px 10px 10px;
    color:#333;
  }
  .wechatSearchInput {
    height: 40px;
    border: solid 1px #d5d5d5;
    background-color: #ffffff;
    margin: 12px 0;
    font-size:1.2rem;
    padding:10px;
    width:100%;
  }
  .search{
    width:100%;
    height:40px;
    font-size:1.4rem;
    background-color:#ed4218;
    &:active, &:focus, &:hover {font-size:1.4rem;}
  }
  .link {
    text-align: center;
    font-size:1.2rem;
    display:block;
    color: #333;
    margin-top:24px;
  }
  .search:hover, .search:visited {color: #ccc}
  .link:active, .link:visited, .link:hover {color: #999}
</style>
