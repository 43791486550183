<template>
  <div>
    <v-dialog />
    <web-header></web-header>
    <router-view></router-view>
    <web-footer v-show="showFooter"></web-footer>
  </div>
</template>

<script>
  import WebFooter from '../templates/CustomerWebFooter.vue'
  import WebHeader from '../templates/CustomerWebHeader.vue'
  export default {
    data: function() {
      return {
        searchKeyword: '',
        brands: '',
        showFooter: true,
      }
    },
    components: {
      'web-footer': WebFooter,
      'web-header': WebHeader,
    },
    methods: {
      search() {
        if (this.searchKeyword.length == 0)
          return;
        this.$router.push({
          name: 'productListBySearch',
          query: { keyword: this.searchKeyword, brands: this.brands },
        });
      }
    },
  }
</script>
