<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <!--<img src="https://cdn.mobag.cn/953198c0ed93d3dd61d991d191c525d4" />-->
        <div class="title">
          <div class="bar"></div>
          <span class="en">Contact Us</span>
          <span class="ch">联系我们</span>
        </div>
        <p>
          {{ $t('about.contact.address') }}
        </p>
        <p>
          {{ $t('about.contact.email') }}<a href="mailto:info@moreruns.com">info@moreruns.com</a>
        </p>
        <p>
          <img src="https://cdn.mobag.cn/barcode_search.png" style="width: 40rem" alt="">
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = '摩燃-联系我们';
  }
}
</script>

<style scoped>
  .title {
    left: -210px;
  }
  @media (max-width:900px) {
    .introduction-bg {
      .title {
        left:0;
        top: -100px;
      }
    }
  }
</style>
