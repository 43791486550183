var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "introduction-bg" }, [
      _c(
        "div",
        { staticClass: "dialog-bg" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.$t("about.faq.qaList"), function(qa) {
            return _c("p", [
              _c("b", [_vm._v(_vm._s(qa[0]))]),
              _vm._v(" "),
              _c("br"),
              _vm._v("\n        " + _vm._s(qa[1]) + "\n      ")
            ])
          }),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$t("about.faq.purchaseAssistant")))]),
            _c("br"),
            _vm._v(" "),
            _c("img", {
              staticStyle: { width: "12rem" },
              attrs: { src: _vm.sellQrCode, alt: "" }
            })
          ]),
          _vm._v(" "),
          _c("p", [
            _c("b", [_vm._v(_vm._s(_vm.$t("about.faq.wechat")))]),
            _c("br"),
            _vm._v(" "),
            _c("img", {
              staticStyle: { width: "40rem" },
              attrs: { src: "https://cdn.mobag.cn/barcode_search.png", alt: "" }
            })
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "placeholder" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "en" }, [_vm._v("Q & A")]),
      _vm._v(" "),
      _c("span", { staticClass: "ch" }, [_vm._v("常见问题")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }