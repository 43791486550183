var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "item-container" },
    [
      _c(
        "router-link",
        {
          attrs: {
            target: "_blank",
            to: { name: "productDetail", params: { id: _vm.product.id } }
          }
        },
        [
          _c("div", { staticClass: "pic" }, [
            _c("img", { attrs: { src: _vm.pic.url + "?imageView2/2/w/300" } }),
            _vm._v(" "),
            _vm.hasVideo
              ? _c("img", {
                  staticClass: "play-icon",
                  attrs: { src: require("../../images/play.png") }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-tag" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.product.traitIdList.indexOf(1) != -1,
                    expression: "product.traitIdList.indexOf(1) != -1"
                  }
                ],
                staticClass: "tag-new"
              },
              [_c("span", { staticClass: "new" }, [_vm._v("现货")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.product.traitIdList.indexOf(2) != -1 &&
                      _vm.product.traitIdList.indexOf(1) != -1,
                    expression:
                      "product.traitIdList.indexOf(2) != -1 && product.traitIdList.indexOf(1) != -1"
                  }
                ],
                staticClass: "tag-hot"
              },
              [_c("span", { staticClass: "hot" }, [_vm._v("特价")])]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "desc" },
        [
          _c(
            "router-link",
            {
              attrs: {
                target: "_blank",
                to: { name: "productDetail", params: { id: _vm.product.id } }
              }
            },
            [
              _vm.product.chineseTitle
                ? _c("span", [_vm._v(_vm._s(_vm.product.chineseTitle))])
                : _c("span", [_vm._v(_vm._s(_vm.product.title))])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }