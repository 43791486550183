<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <!--<img src="https://cdn.mobag.cn/953198c0ed93d3dd61d991d191c525d4" />-->
        <div class="title">
          <div class="bar"></div>
          <span class="en">Privacy</span>
          <span class="ch">隐私声明</span>
        </div>

        <p v-for="text in $t('about.privacyStatement')">{{ text }}</p>

      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = '摩燃-隐私声明';
  }
}
</script>

<style scoped>
  .title {
    left: -155px;
  }
  @media (max-width:900px) {
    .introduction-bg {
    .title {
      left:0;
      top: -100px;
    }
  }
  }
</style>
