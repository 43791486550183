<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div class="mobile" :class="[!tangleMenuModal ? 'close' : '']">
    <MobileHeader @close="close()"></MobileHeader>
    <div class="linkContainer">
      <div class="item"  @click="linkToDealer"><v-icon class="icon" name="truck"></v-icon><span>{{ $t("header.dealerLogin") }}</span></div>
    </div>
    <b-list-group>
      <b-list-group-item @click="close()">
        <a href="#" @click="(e) => {reloadPage('/home', e)}">{{ $t("header.homepage") }}</a>
      </b-list-group-item>
      <b-list-group-item @click="close()">
        <a href="#" @click="(e) => {reloadPage('/home/product/list/tag/0', e)}">{{ $t("header.parts") }}</a>
      </b-list-group-item>
      <b-list-group-item @click="close()">
        <a href="#" @click="(e) => {reloadPage('/home/product/list/tag/4', e)}">{{ $t("header.allGears") }}</a>
      </b-list-group-item>
      <!-- <b-list-group-item @click="close()">
        <a href="#" @click="(e) => {reloadPage('/home/product/quotaion/list/page', e)}">{{ $t("header.quotationPage") }}</a>
      </b-list-group-item> -->
      <b-list-group-item @click="close()">
        <a target="_blank" href="https://dealer.mobag.cn/">{{ $t("header.dealerLogin") }}</a>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
  import MobileHeader from './public/HeaderBlack.vue'
  import { baseUrl } from '../../util.js'

  export default {
    components: {
      MobileHeader
    },
    data: function() {
      return {
        tangleMenuModal: false,
        searchText: ''
      }
    },
    methods: {
      close: function() {
        this.tangleMenuModal = false;
        this.$emit('tangle');
      },
      handleParentClick: function(st) {
        this.tangleMenuModal =  st
      },
      linkToDealer() {
        window.location.href = baseUrl.dealer;
      },
      reloadPage(url, e){
        e.preventDefault();
        var currentUrl = this.$route.fullPath;
        let end_index = currentUrl.indexOf('?')>-1 ? currentUrl.indexOf('?') : currentUrl.length
        currentUrl = currentUrl.substring(0, end_index)
        if(currentUrl.endsWith(url)){
          let newUrl = url + '?_=' + new Date().getTime()
          this.$router.replace(newUrl);
        } else {
          this.$router.push(url)
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .mobile {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:#FAFAFA;
    z-index:9;
  }
  .close {
    display:none
  }
  .linkContainer {
    background-color:#000;
    padding:6px;
    .item {
      width:134px;
      height:36px;
      display:flex;
      align-items: center;
      justify-content: center;
      background-color:rgba(255,255,255,0.2);
      cursor: pointer;
      span{color:#fff;}
      .icon{margin-right:5px;}
    }
  }
  a{font-size: 1.4rem; display: block; line-height:48px; padding-left:16px;}
  a:hover { text-decoration: none;}
  a:active { color: #ec2f00}
</style>
