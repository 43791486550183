// Vue插件：对业务码进行封装
import Vue from 'vue'

import app from '../main.js'

var BizCode = {

  install: function (Vue, options) {
    Vue.bizCode = Vue.prototype.$bizCode = BizCode;
  },

  tagType: {
    0: '配件',
    1: '头盔',
    2: '服饰',
    3: '周边',
  },

  trait: {
    1: '现货',
    2: '特价',
  },

  SpecType: [
    null, '颜色', '尺寸',
  ],
};

export default BizCode;
