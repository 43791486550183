var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "introduction-bg" }, [
      _c("div", { staticClass: "dialog-bg" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n        " + _vm._s(_vm.$t("about.contact.address")) + "\n      "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("\n        " + _vm._s(_vm.$t("about.contact.email"))),
          _c("a", { attrs: { href: "mailto:info@moreruns.com" } }, [
            _vm._v("info@moreruns.com")
          ])
        ]),
        _vm._v(" "),
        _vm._m(1)
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "placeholder" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "en" }, [_vm._v("Contact Us")]),
      _vm._v(" "),
      _c("span", { staticClass: "ch" }, [_vm._v("联系我们")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticStyle: { width: "40rem" },
        attrs: { src: "https://cdn.mobag.cn/barcode_search.png", alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }