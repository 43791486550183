var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: [!_vm.tangleMenuModal ? "close" : "imgShow"] }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "closeIcon" }, [
        _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.close()
              }
            }
          },
          [_c("v-icon", { attrs: { name: "times", scale: "1.2" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("img", { attrs: { src: _vm.imgUrl } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }