<template>
  <div style="background-color:#fafafa; min-width:420px;">
    <div class="top-tab" v-show="currentStep === 1">
      <div class="cell" :class="{'current': currentTab===0}" @click="onTabChange(0)">
        <v-icon name="link" class="icon" /><span>已有账号，请绑定</span>
      </div>
      <div class="cell" :class="{'current': currentTab===1}" @click="onTabChange(1)">
        <v-icon name="edit" class="icon" /><span>没有账号，请完善资料</span>
      </div>
    </div>
    <div class="main pb40" v-show="currentStep === 1">
      <div class="pt60" id="step1" v-show='currentTab===1'>
        <b-input-group prepend="用户名" class="register">
          <!--          <b-form-input id="countryCode" placeholder="+86" aria-label="First name" class="country-code border1" v-model="countryCode"></b-form-input>-->
          <b-form-input
                  id='nickName'
                  class="border3"
                  v-model="nickName"
                  placeholder="请设置您的用户登录名"
          />
        </b-input-group>

        <b-input-group prepend="设置密码" class="mt20 register">
          <b-form-input
                  type="password"
                  id="password"
                  class="border3"
                  placeholder="建议使用两种或以上的字符"
                  v-model="password"
          />
        </b-input-group>

        <b-input-group prepend="确认密码" class="mt20 register">
          <b-form-input
                  type="password"
                  id="rePassword"
                  class="border3"
                  placeholder="请再次输入密码"
                  v-model="rePassword"
          />
        </b-input-group>

        <b-input-group prepend="请输入手机号" class="mt20 register">
          <!--          <b-form-input id="countryCode" placeholder="+86" aria-label="First name" class="country-code border1" v-model="countryCode"></b-form-input>-->
          <b-form-input
                  id='phone'
                  class="border3"
                  v-model="phone"
                  @change="onPhoneChange($event)"
                  placeholder="请输入手机号"
          />
        </b-input-group>

        <b-input-group prepend="手机验证码" class="mt20 register">
          <b-form-input class="border2" v-model="smsCode" id="smsCode" placeholder="请输入验证码"/>
          <b-input-group-append>
            <b-button
                    class="btn-send-code"
                    style="border-radius:4px; width:108px"
                    @click="sendSMSCode()"
            >
              {{smsStr}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="button-lg-red mt70" @click="goRegist()">
          <a href="#">立即注册</a>
        </div>
      </div>

      <div class="pt30" id="step2" v-show='currentTab===0'>
        <div class="topGreyContainer">
          <p>Hi，{{nickName}}，完成绑定后可以微信一键登录哦~~~</p>
        </div>
        <b-input-group prepend="账号" class="register">
          <!--          <b-form-input id="countryCode" placeholder="+86" aria-label="First name" class="country-code border1" v-model="countryCode"></b-form-input>-->
          <b-form-input
                  id='bindAccount'
                  class="border3"
                  v-model="bindAccount"
                  placeholder="手机号"
          />
        </b-input-group>

        <b-input-group prepend="密码" class="mt20 register">
          <b-form-input
                  type="password"
                  id="bindPassword"
                  class="border3"
                  placeholder="建议使用两种或以上的字符"
                  v-model="bindPassword"
          />
        </b-input-group>
        <div class="button-lg-red mt70" @click="bindInfo()">
          <a href="#">立即绑定</a>
        </div>
      </div>
    </div>
    <div class="mt120" id="step3" v-show="currentStep===2">
      <div class="tips">
        <v-icon name="check-circle" />
        <div class="mt20">账号绑定成功！</div>
      </div>
    </div>
  </div>
</template>

<script>
  const defaultSmsStr = '获取验证码';
  const defaultSmsCount = 60;
  export default {
    data() {
      return {
        currentStep:1,
        smsStr: defaultSmsStr,
        sec: defaultSmsCount,
        isSmsSending: false,
        timer: null,
        currentTab: 1,
        smsCode: null,
        nickName:this.$route.query.nick_name,
        password:null,
        rePassword: null,
        phone: null,
        isGoRegistClick: false,
        isGoBindInfoClick: false,
        bindAccount: null,
        bindPassword:null,
      }
    },
    watch: {
      sec: function (val) {
        if (val === -1) {
          this.timer = clearInterval(this.timer);
          this.isSmsSending = false;
          this.smsStr = defaultSmsStr;
          this.sec = defaultSmsCount;
        }
      }
    },
    methods: {
      bindInfo() {
        if(this.isGoBindInfoClick || !this.checkStep2()) {
          return;
        }
        this.isGoBindInfoClick = true;
        let self = this;
        this.$api.post(`/wechat_bind_customer`, {
          phone: this.bindAccount,
          password: this.bindPassword,
        })
          .then(data => {
            self.isGoBindInfoClick = false;
            if(data.code !== 0) {
              self.$toast.error(data.msg);
              return;
            }
            self.currentStep = 2;
            this.$emit('loginCallBack');
          }, (err) => {
            self.$toast.error(err.msg);
            self.isGoBindInfoClick = false;
          });
      },
      onTabChange(num) {
        if(num ===0) {
          this.currentTab = 0
        } else {
          this.currentTab = 1
        }
      },
      onPhoneChange(value) {
        this.phone = value.replace(/[^\0-9]/g, '')
      },
      sendSMSCode() {
        if(!this.phone || this.isSmsSending || (this.timer && this.sec < defaultSmsCount)) {
          return;
        }
        let self = this;

        this.isSmsSending = true;
        this.timer = setInterval(function(){
          self.sec --;
          self.smsStr = '重新发送（' + self.sec + 's）'
        }, 1000)

        this.$api.post(`/wechat_register_send_sms_code`, {phone: this.phone})
          .then(data => {
            self.isSmsSending = false;
            if(data.code === 0 && data.msg === 'OK') {
              console.log('send sms code successfully')
            }
            else {
              self.$toast.error(data.msg)
            }
          }, (err) => {
            console.log('send sms code failed');
            self.$toast.error(err.msg);
            self.isSmsSending = false;
          });
      },

      goRegist() {
        if(this.isGoRegistClick || !this.checkStep1()) {
          return;
        }
        this.isGoRegistClick = true
        let self = this;
        this.$api.post(`/wechat_complete_register_info`, {
          phone: this.phone,
          smsCode: this.smsCode,
          nickName: this.nickName,
          password: this.password,
          passwordRepeat: this.rePassword,
        })
          .then(data => {
            self.isGoRegistClick = false;
            if(data.code !== 0) {
              self.$toast.error(data.msg);
              return;
            }
            self.currentStep = 2;
            this.$emit('loginCallBack');
          }, (err) => {
            self.$toast.error(err.msg);
            self.isGoRegistClick = false;
          });
      },

      checkStep1() {
        // let elCountryCode = document.getElementById('countryCode');
        let elPhone = document.getElementById('phone');
        let elUsername = document.getElementById('nickName');
        let elSmsCode = document.getElementById('smsCode');
        let elPassword = document.getElementById('password');
        let elRePassword = document.getElementById('rePassword');

        elPassword.removeAttribute('style')
        elRePassword.removeAttribute('style')

        elUsername.removeAttribute('style')
        elPhone.removeAttribute('style')
        elSmsCode.removeAttribute('style')
        if(!this.password) {
          elPassword.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.phone) {
          elPhone.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.smsCode) {
          elSmsCode.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.rePassword) {
          elRePassword.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.nickName) {
          elUsername.setAttribute('style', 'border:1px solid #ec2f00')
        }
        return !(!this.password || !this.rePassword || !this.nickName || !this.phone || !this.smsCode);
      },
      checkStep2() {
        let elAccount = document.getElementById('bindAccount');
        let elPassword = document.getElementById('bindPassword');

        elAccount.removeAttribute('style');
        elPassword.removeAttribute('style');
        if(!this.bindAccount) {
          elAccount.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.bindPassword) {
          elPassword.setAttribute('style', 'border:1px solid #ec2f00')
        }
        return !(!this.bindAccount || !this.bindPassword);
      },

    }
  }
</script>

<style scoped>
  .top-tab{
    width:562px; margin:0 auto; padding-top:80px; display:flex;
    .cell {
      flex:1;
      border-bottom:2px solid #eee;
      display:flex;
      align-items: center;
      justify-content: center;
      font-size:1.6rem;
      line-height:3.2rem;
      cursor: pointer;
      .icon{margin-right:10px;}
    }
    .current {
      border-bottom:2px solid #000;
    }
  }
  .main {width:400px; margin: 0 auto;}
  .steps-process {
    display:flex;
    align-items:center;
    .item{width:80px; text-align:center}
    .circle {
      background-color: white;
      border: solid 1px #000;
      width:38px;
      height:38px;
      display:block;
      margin: 0 auto 4px;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      span{color: black;font-family: 'teko'; font-size:2.6rem; margin-top:4px;}
    }
    span {margin-top:8px;}
    .current {
      background-color: black;
      border: solid 1px #fff;
      span{color: white;}
    }
    .line {flex:1; border-bottom:1px dashed #000; height:1px; margin:0 0 20px}
  }
  .country-code{width:78px; flex:none; margin-right:10px;}
  .border1{border:1px solid #ccc;}
  .borderRed{border:1px solid #red}
  .border2{
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    background-color: #fff;
  }
  .border3{
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
    background-color: #fff;
  }
  .register .input-group-text {
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-left:1px solid #ccc;
    background-color: #fff;
    width:90px;
    justify-content: center;
  }
  .btn-send-code {
    background-color: #f1f1f1;
    color: #333;
    margin:5px;
  }
  .input-group-append {
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
    background-color:#fff
  }
  .input-group .form-control {height:50px;}
  .tips {text-align:center; font-size:1.8rem; color: #999}

  .item svg {display:none}
  .item .passed svg {color: #000; display:inline-block; margin-top:10px; text-align:center;}
  .item .passed span {display:none}
  .tips svg {display:block; color:#1bb934; width: 80px; height:80px; margin:0 auto;}

  .topGreyContainer{
    background-color:#f1f1f1; padding:10px; margin-bottom:60px;
    p{font-size:1.2rem; color:#333;line-height:2rem; margin:0}
  }
  @media (max-width:767px){
  }

  @media (max-width: 575px) {
  }
</style>
