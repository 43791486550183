<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div :class="[!tangleMenuModal ? 'close' : 'imgShow']">
      <div class="main">
        <div class="closeIcon"><a @click="close()">
          <v-icon name="times" scale="1.2" ></v-icon></a>
        </div>
        <img :src=imgUrl>
      </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tangleMenuModal: false,
      imgUrl: undefined,
    };
  },
  methods: {
    close() {
      this.tangleMenuModal = false;
      this.$emit('tangle');
    },
    handleParentClick(st) {
      this.tangleMenuModal = st;
    },
    setImgUrl(url) {
      this.imgUrl = url;
    },
  },
};
</script>

<style scoped lang="scss">
  img{ width:100%; }
  .close {
    display:none
  }
  .main {
      width:420px;
      max-width:80%;
      margin:0 auto 0;
      text-align:center;
  }
  .closeIcon {
    display:flex;
    justify-content: flex-end;
    color:white;
    margin-bottom:20px;
  }
  .imgShow {
    display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.6);
    z-index:9;
  }
</style>
