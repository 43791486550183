var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { staticClass: "map-container" },
        [
          _c("b-col", { staticClass: "row lg" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "3", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://cdn.mobag.cn/rinehart-staggered.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "40", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/zan_2.jpg" }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "7", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/k_3.jpg" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "241,979", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/goldstrike_2.png\t" }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "38", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/tbr_2.png" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "12", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/bobster_2.jpg" }
                    })
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("b-col", { staticClass: "row sm" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "3", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "https://cdn.mobag.cn/rinehart-staggered.jpg"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "40", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/zan_2.jpg" }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "7", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/k_3.jpg" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "productListBySearch",
                        query: { brands: "241,979", keyword: "" }
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: "https://cdn.mobag.cn/goldstrike_2.png\t" }
                    })
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }