var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.linkToQuotationPage(_vm.item.urlSuffix)
            }
          }
        },
        [
          _c("img", {
            staticStyle: { height: "60px" },
            attrs: { src: _vm.operaImg(_vm.item.shareImage) }
          })
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.linkToQuotationPage(_vm.item.urlSuffix)
            }
          }
        },
        [
          _c("div", { staticClass: "price" }, [
            _c("span", [_vm._v(_vm._s(_vm.item.title))])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _c("div", { staticClass: "money" }, [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.linkToQuotationPage(_vm.item.urlSuffix)
                }
              }
            },
            [_c("span", [_vm._v(_vm._s(_vm.item.shareDescription))])]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }