let baseUrl = {};
if (process.env.NODE_ENV === 'production') {
  baseUrl.web = 'https://mobag.cn';
  baseUrl.dealer = 'https://dealer.mobag.cn';
} else {
  baseUrl.web = 'http://localhost:8081';
  baseUrl.dealer = 'http://localhost:8082';
}



module.exports = {
  baseUrl : {
    web: baseUrl.web,
    dealer: baseUrl.dealer
  }
}
