var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchContainer", attrs: { id: "wechatSearchByBike" } },
    [
      _c("hr", {
        staticClass: "hr-text",
        attrs: { "data-content": "按名称或编号搜索" }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchKeyword,
            expression: "searchKeyword"
          }
        ],
        staticClass: "wechatSearchInput",
        attrs: { type: "text", placeholder: "输入商品名称或配件编号" },
        domProps: { value: _vm.searchKeyword },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.directSearch()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchKeyword = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("btn-orange", {
        staticClass: "search",
        attrs: { click: _vm.directSearch, text: "搜索" }
      }),
      _vm._v(" "),
      _c("hr", {
        staticClass: "hr-text",
        staticStyle: { margin: "80px 0 30px 0" },
        attrs: { "data-content": "按车型搜索" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-row" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "dp-item",
              attrs: { text: _vm.selectedBrandName || "品牌" }
            },
            _vm._l(_vm.showBrandList, function(brand) {
              return _c(
                "b-dropdown-item",
                {
                  key: brand.id,
                  attrs: { value: brand.id },
                  on: {
                    click: function($event) {
                      return _vm.saveSelection("brand", brand.id, brand.name)
                    }
                  }
                },
                [_vm._v(_vm._s(brand.name))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-icon", {
            staticClass: "icon",
            attrs: { name: "angle-down", scale: "1.4" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-row" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "dp-item",
              attrs: { text: _vm.selectedSeriesName || "车系" }
            },
            _vm._l(_vm.showSeriesList, function(series) {
              return _c(
                "b-dropdown-item",
                {
                  key: series.id,
                  attrs: { value: series.id },
                  on: {
                    click: function($event) {
                      return _vm.saveSelection("series", series.id, series.name)
                    }
                  }
                },
                [_vm._v(_vm._s(series.name))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-icon", {
            staticClass: "icon",
            attrs: { name: "angle-down", scale: "1.4" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-row" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "dp-item",
              attrs: { text: _vm.selectedModelName || "车型" }
            },
            _vm._l(_vm.showModelList, function(model) {
              return _c(
                "b-dropdown-item",
                {
                  key: model.id,
                  attrs: { value: model.id },
                  on: {
                    click: function($event) {
                      return _vm.saveSelection("model", model.id, model.name)
                    }
                  }
                },
                [_vm._v(_vm._s(model.name))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-icon", {
            staticClass: "icon",
            attrs: { name: "angle-down", scale: "1.4" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "search-row" },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "dp-item",
              attrs: { text: _vm.selectedYear || "年份" }
            },
            _vm._l(_vm.showYearList, function(year) {
              return _c(
                "b-dropdown-item",
                {
                  key: year,
                  attrs: { value: year },
                  on: {
                    click: function($event) {
                      _vm.saveSelection("year", null, year.toString())
                    }
                  }
                },
                [_vm._v(_vm._s(year))]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-icon", {
            staticClass: "icon",
            attrs: { name: "angle-down", scale: "1.4" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("btn-orange", {
        staticClass: "search",
        attrs: { click: _vm.search, text: "搜索" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }