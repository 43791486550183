<template>
  <div style="padding-bottom: 50px;">
    <div class="top-container"  style="text-align: center;">
      <p class="title-size">{{sharePageInfo.title}}</p>
    </div>

    <div class="main-list">
      <b-table style="text-align: center; font-size: 16px;"
               :items="productList" :fields="fields" :emptyText="emptyText"
               striped show-empty>
        <template v-slot:cell(image)="data">
          <div>
            <a style="cursor: pointer;" @click='tangleImgEnlarged(data.item.image)'>
              <img :src="operaImg(data.item.image)" onerror="this.src='https://cdn.mobag.cn/9dc79963d75b6b8785c47caf8de091a3?imageView2/2/w/100/h/100'">
            </a>
          </div>
        </template>
        <template v-slot:cell(brandAndPartNumber)="data">
          <div class="center">
            <span style="font-weight: bolder">{{data.item.brand}}</span>
          </div>
          <div style="margin-top: 5px;">
            <span>{{data.item.partNumber}}</span>
          </div>
        </template>
        <template v-slot:cell(description)="data">
          <div class="center"><p>{{data.item.description}}</p></div>
        </template>
        <template v-slot:cell(currentPrice)="data">
          <div class="center"><p>{{data.item.currentPrice/100}}</p></div>
        </template>
      </b-table>
    </div>
    <img-enlarged ref="imgEnlarged" @tangle="tangleImgEnlarged()" />

    <div class="main-list-mobile">
      <mobile-list-item
        class="mobileItems"
        v-for="(item, index) in productList"
        v-bind:key="index"
        :item='item'
     />
     <p class="emptyForMobile" v-show="productList.length===0" style="font-size:1.2rem">{{emptyText}}</p>
    </div>
  </div>
</template>

<script>
import ImgEnlarged from '../../components/public/ImgEnlarged.vue';
import MobileListItem from '../../components/public/MobileQuotationListItem.vue';
import {baseUrl} from "../../util";
import wx from 'weixin-js-sdk'

export default {
  components: {
    'img-enlarged': ImgEnlarged,
    'mobile-list-item': MobileListItem,
  },
  data: function() {
    return {
      fields: [
        { key: 'image', label: '图片', thStyle:{'width':'150px','background-color':'#ec2f00','color':'#fff'} },
        { key: 'brandAndPartNumber', label: '品牌型号', thStyle:{'width':'150px','background-color':'#ec2f00','color':'#fff'} },
        { key: 'description', label: '中文描述', thStyle:{'background-color':'#ec2f00','color':'#fff'} },
        { key: 'currentPrice', label: '建议零售价¥', thStyle:{'width':'150px','background-color':'#ec2f00','color':'#fff'} },
      ],
      emptyText: '',
      sharePageInfo: {},
      productList: [],
      tagUrl: `/query_quotation_page_detail?url_suffix=${this.$route.params.urlSuffix}&qrcode=${this.$route.params.qrcode}`,
      weChatParamsUrl: '/get_wechat_share_params',
      isImgEnlargedOpen: false,
      salesQrCode: '',
      pageTitle: ''
    }
  },
  created: function() {
    this.$api.get(this.tagUrl)
        .then(data => {
          this.sharePageInfo = data.data.page;
          this.productList = data.data.records;
          this.pageTitle = data.data.page.title;
          this.salesQrCode = data.data.page.salesQrCode
          window.document.title = this.pageTitle;
          this.initWechat();
      });
  },
  methods: {
    initWechat() {
      this.$api.post(this.weChatParamsUrl,{'current_url': String(window.location)})
        .then(data => {
          // console.log(JSON.stringify(data))
          wx.config({
            debug: false,
            appId: data.data.appId,
            timestamp: data.data.timestamp,
            nonceStr: data.data.nonceStr,
            signature: data.data.signature,
            jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage']
          })
          let share_title = this.sharePageInfo.title;
          let share_link = String(window.location);
          let share_img = this.sharePageInfo.shareImage;
          let share_desc = this.sharePageInfo.shareDescription;
          wx.ready(function () {
            var options = {
                title: share_title,
                link: share_link,
                imgUrl: share_img,
                desc: share_desc,
                success: function () {
                    console.info('分享成功');
                },
                cancel: function () {
                    console.info('取消分享');
                }
            };
            wx.onMenuShareTimeline(options);
            wx.onMenuShareAppMessage(options);
          });
      });
    },
    operaImg(url) {
      return this.operaUrlTo150(url);
    },
    tangleImgEnlarged(imgUrl) {
      this.isImgEnlargedOpen = !this.isImgEnlargedOpen;
      this.$refs.imgEnlarged.handleParentClick(this.isImgEnlargedOpen);
      this.$refs.imgEnlarged.setImgUrl(this.operaUrlTo400(imgUrl));
    },
    operaUrlTo400(url) {
      if (!url) {
        return url;
      }
      const checkUrl = url.search('imageView');
      if (checkUrl !== -1) {
        const base = url.split('/w/')[0];
        return `${base}/w/400/h/400`;
      }
      return `${url}?imageView2/2/w/400/h/400`;
    },
    operaUrlTo150(url) {
      if (!url) {
        return url;
      }
      const checkUrl = url.search('imageView');
      if (checkUrl !== -1) {
        const base = url.split('/w/')[0];
        return `${base}/w/150/h/150`;
      }
      return `${url}?imageView2/2/w/150/h/150`;
    },
    linkToDealer() {
        window.location.href = baseUrl.dealer;
    },
    linkToReg() {
        window.location.href = baseUrl.dealer + '#/register';
    }
  }
}
</script>
<style scoped lang="scss">
.main-list {border:1px solid #e1e1e1; max-width: 1000px;margin: 50px auto;}
.main-list-mobile, .mobile {display:none}
.center {
  text-align: center;
  padding-top: 30px;
}
.top-container {
    margin:50px 8px 8px 8px;
  }
.title-size{ font-size: 30px;}
.mobag-header {
  background-color: black;
  color: white;
  padding:10px;
  .mobag-header-container{
    max-width: 1000px;
    height: 64px;
    margin: 0 auto;
    align-items: center;
    .mobag-logo{width:180px;float: left;margin-top: 10px;}
    img {
      width: 110px;
    }
  }
  .dealer-login {
    float: right;
    text-align:center;
    margin: 0 15px;
    height: 60px;
    a{
      color: white;
      font-size:14px;
      line-height:60px;
      cursor: pointer;
      .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
    }
  }
}

@media screen and(max-width:575px) {
  .top-container {
    margin:50px 8px 8px 8px;
    padding-top:80px;
  }
}
@media screen and (max-width:767px) {
  .top-container {
    margin:50px 8px 8px 8px;
  }
  .page-top {
    margin:8px;
    .page-top-title, .search-btn-container{display:none}
  }
  .search-input {max-width:100%; margin-left:0}
  .dealer-route-container {padding:0px;}
  .main-list {display:none}
  .search-btn-container {display:none}
  .main-list-mobile {
    display:block;
    background-color:#fafafa;
    margin: 20px 20px;
  }
  .mobileItems:last-child {border-bottom:1px solid #eee}
  .emptyForMobile {margin:10px;}
  .web {display:none}
  .mobile{display: block}
  .title-size{ font-size: 24px;}
  .mobag-header {
    padding:10px;
    .mobag-header-container {
      .mobag-logo{width:120px;float: left;margin-top: 10px;}
      img{ width:110px;}
    }
    .dealer-login {
      float: right;
      text-align:center;
      margin: 0 5px;
      height: 60px;
      a{
        color: white;
        font-size:12px;
        line-height:60px;
        cursor: pointer;
        .icon {margin-right:4px; width:1.2rem; height:1.2rem; margin-top:-3px;}
      }
    }
  }
}
</style>