// Vue插件：对Modal进行封装
import Vue from 'vue'

import app from '../main.js'

var ModalHelper = {

  install: function (Vue, options) {
    Vue.modalHelper = Vue.prototype.$modalHelper = ModalHelper;
  },

  hide: function () {
    app.$modal.hide('dialog');
  },

  // 提示确认
  alert: function (msg, callback) {
    app.$modal.show('dialog', {
      text: msg,
      buttons: [
      {
        title: '好的',
        handler: () => {
          if (callback !== undefined)
            callback();
          this.hide();
        },
      },
      ]
    });
  },

  // 确认/取消 对话框
  check: function (msg, callback) {
    app.$modal.show('dialog', {
      text: msg,
      buttons: [
      {
        title: '确认',
        handler: () => {callback()},
      },
      {
        title: '取消',
      },
      ]
    });
  },
};

export default ModalHelper;
