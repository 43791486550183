var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _vm.banners.length
        ? _c(
            "swiper",
            {
              ref: "bannerSwiper",
              staticStyle: { position: "relative" },
              attrs: { options: _vm.swiperOption }
            },
            [
              _vm._l(_vm.banners, function(banner, index) {
                return _c("swiper-slide", { key: index }, [
                  _c("img", {
                    attrs: { src: banner.url },
                    on: {
                      click: function($event) {
                        return _vm.toDetail(banner)
                      }
                    }
                  })
                ])
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination"
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "swiper-button",
                  attrs: { slot: "button-prev" },
                  slot: "button-prev"
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "arr-container swiper-button-prev",
                      staticStyle: { left: "5%" }
                    },
                    [
                      _c("v-icon", {
                        staticClass: "arr",
                        attrs: {
                          name: "chevron-left",
                          scale: "1.1",
                          color: "#CED4DA"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "arr-container swiper-button-next",
                      staticStyle: { right: "5%" }
                    },
                    [
                      _c("v-icon", {
                        staticClass: "arr",
                        attrs: {
                          name: "chevron-right",
                          scale: "1.1",
                          color: "#CED4DA"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }