var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "background-color": "#fafafa", "min-width": "420px" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 1,
              expression: "currentStep === 1"
            }
          ],
          staticClass: "top-tab"
        },
        [
          _c(
            "div",
            {
              staticClass: "cell",
              class: { current: _vm.currentTab === 0 },
              on: {
                click: function($event) {
                  return _vm.onTabChange(0)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "icon", attrs: { name: "link" } }),
              _c("span", [_vm._v("已有账号，请绑定")])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "cell",
              class: { current: _vm.currentTab === 1 },
              on: {
                click: function($event) {
                  return _vm.onTabChange(1)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "icon", attrs: { name: "edit" } }),
              _c("span", [_vm._v("没有账号，请完善资料")])
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 1,
              expression: "currentStep === 1"
            }
          ],
          staticClass: "main pb40"
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 1,
                  expression: "currentTab===1"
                }
              ],
              staticClass: "pt60",
              attrs: { id: "step1" }
            },
            [
              _c(
                "b-input-group",
                { staticClass: "register", attrs: { prepend: "用户名" } },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: {
                      id: "nickName",
                      placeholder: "请设置您的用户登录名"
                    },
                    model: {
                      value: _vm.nickName,
                      callback: function($$v) {
                        _vm.nickName = $$v
                      },
                      expression: "nickName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-input-group",
                {
                  staticClass: "mt20 register",
                  attrs: { prepend: "设置密码" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: {
                      type: "password",
                      id: "password",
                      placeholder: "建议使用两种或以上的字符"
                    },
                    model: {
                      value: _vm.password,
                      callback: function($$v) {
                        _vm.password = $$v
                      },
                      expression: "password"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-input-group",
                {
                  staticClass: "mt20 register",
                  attrs: { prepend: "确认密码" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: {
                      type: "password",
                      id: "rePassword",
                      placeholder: "请再次输入密码"
                    },
                    model: {
                      value: _vm.rePassword,
                      callback: function($$v) {
                        _vm.rePassword = $$v
                      },
                      expression: "rePassword"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-input-group",
                {
                  staticClass: "mt20 register",
                  attrs: { prepend: "请输入手机号" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: { id: "phone", placeholder: "请输入手机号" },
                    on: {
                      change: function($event) {
                        return _vm.onPhoneChange($event)
                      }
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-input-group",
                {
                  staticClass: "mt20 register",
                  attrs: { prepend: "手机验证码" }
                },
                [
                  _c("b-form-input", {
                    staticClass: "border2",
                    attrs: { id: "smsCode", placeholder: "请输入验证码" },
                    model: {
                      value: _vm.smsCode,
                      callback: function($$v) {
                        _vm.smsCode = $$v
                      },
                      expression: "smsCode"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-send-code",
                          staticStyle: {
                            "border-radius": "4px",
                            width: "108px"
                          },
                          on: {
                            click: function($event) {
                              return _vm.sendSMSCode()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.smsStr) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "button-lg-red mt70",
                  on: {
                    click: function($event) {
                      return _vm.goRegist()
                    }
                  }
                },
                [_c("a", { attrs: { href: "#" } }, [_vm._v("立即注册")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentTab === 0,
                  expression: "currentTab===0"
                }
              ],
              staticClass: "pt30",
              attrs: { id: "step2" }
            },
            [
              _c("div", { staticClass: "topGreyContainer" }, [
                _c("p", [
                  _vm._v(
                    "Hi，" +
                      _vm._s(_vm.nickName) +
                      "，完成绑定后可以微信一键登录哦~~~"
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "b-input-group",
                { staticClass: "register", attrs: { prepend: "账号" } },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: { id: "bindAccount", placeholder: "手机号" },
                    model: {
                      value: _vm.bindAccount,
                      callback: function($$v) {
                        _vm.bindAccount = $$v
                      },
                      expression: "bindAccount"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-input-group",
                { staticClass: "mt20 register", attrs: { prepend: "密码" } },
                [
                  _c("b-form-input", {
                    staticClass: "border3",
                    attrs: {
                      type: "password",
                      id: "bindPassword",
                      placeholder: "建议使用两种或以上的字符"
                    },
                    model: {
                      value: _vm.bindPassword,
                      callback: function($$v) {
                        _vm.bindPassword = $$v
                      },
                      expression: "bindPassword"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "button-lg-red mt70",
                  on: {
                    click: function($event) {
                      return _vm.bindInfo()
                    }
                  }
                },
                [_c("a", { attrs: { href: "#" } }, [_vm._v("立即绑定")])]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 2,
              expression: "currentStep===2"
            }
          ],
          staticClass: "mt120",
          attrs: { id: "step3" }
        },
        [
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("v-icon", { attrs: { name: "check-circle" } }),
              _vm._v(" "),
              _c("div", { staticClass: "mt20" }, [_vm._v("账号绑定成功！")])
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }