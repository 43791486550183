// import './styles.scss' // ES方法
import './mobagStyle.scss'
import './fonts/font.css'
import 'swiper/dist/css/swiper.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import './vue-quill-editor.css'
import './quill.snow.scss'
// TODO: css中的图片无法自动load，先手动配置，回头再查原因
import './images/a_10.jpg'
import './images/form.png'
import './images/icon6.png'
import './images/icon7.png'
import './images/c_10.jpg'
import './images/c_13.jpg'

import './fonts/TekoRegular.ttf'
import './fonts/PingFangSCRegular.ttf'

import Vue from 'vue'
import VueGtag from "vue-gtag";
import VueRouter from 'vue-router'
import axios from 'axios'
import VueCookie from 'vue-cookie'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VModal from 'vue-js-modal'
import VueI18n from 'vue-i18n'
import Toasted from 'vue-toasted';

import App from './App.vue'
import I18nMessage from './i18n.js'
import BizCode from './common/BizCode.js'
import ApiHelper from './common/ApiHelper.js'
import CookieHelper from './common/CookieHelper.js'
import ModalHelper from './common/ModalHelper.js'
import Toast from './common/ToastHelper'
import titleMixin from './common/titleMixin.js'
////////////////////
// 路由
////////////////////
import Index from './Index.vue'
import WebEntry from './entries/webEntry.vue'
import SimpleEntry from './entries/simpleEntry.vue'
import WechatEntry from './entries/wechatEntry.vue'
import ProductListByTag from './pages/product/ProductListByTag.vue'
import ProductListByBike from './pages/product/ProductListByBike.vue'
import ProductListByMotoBrand from './pages/product/ProductListByMotoBrand.vue'
import ProductListBySearch from './pages/product/ProductListBySearch.vue'
import ProductDetail from './pages/product/ProductDetail.vue'
import About from './pages/about/About.vue'
import AboutUs from './pages/about/AboutUs.vue'
import FAQ from './pages/about/FAQ.vue'
import H2B from './pages/about/How2Buy.vue'
import PS from './pages/about/PrivacyStatement.vue'
import Return from './pages/about/ReturnChange.vue'
import Contact from './pages/about/Contact.vue'
import SearchByBikeForWechat from './wechat/SearchByBike.vue'
import SearchByNameForWechat from './wechat/SearchByName.vue'
import RegisterSteps from "./pages/account/RegisterSteps.vue";
import QuotationPage from "./pages/sell/QuotationPage.vue"
import WebsiteQuotationPage from "./pages/sell/WebSiteQuotationPage.vue"
import QuotationList from "./pages/sell/QuotationList.vue"

import BootstrapVue from 'bootstrap-vue'
import Icon from 'vue-awesome'
import ForgetPassword from "./pages/account/ForgetPassword.vue";
import WechatLogin from "./pages/account/WechatLogin.vue";

Vue.component('v-icon', Icon);

Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.use(VueAwesomeSwiper);
Vue.use(VModal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true
});

Vue.use(BizCode);
Vue.use(ApiHelper);
Vue.use(CookieHelper);
Vue.use(Toast);
Vue.use(ModalHelper);
Vue.use(VueI18n);
Vue.use(Toasted);
Vue.mixin(titleMixin);

const router = new VueRouter({
  routes: [
    {
      path: '/', redirect: 'home'
    },
    {
      path: '/home', component: WebEntry,
      children: [
        { path: '', name:'index', component: Index },
        { path: 'product/list/tag/:tagType', name: 'productListByTag', component: ProductListByTag },
        { path: 'product/list/bike', name: 'productListByBike', component: ProductListByBike },
        { path: 'product/list/moto_brand', name: 'productListByMotoBrand', component: ProductListByMotoBrand },
        { path: 'product/list/search', name: 'productListBySearch', component: ProductListBySearch },
        { path: 'product/detail/:id', name: 'productDetail', component: ProductDetail },
        { path: 'product/quotation/:urlSuffix', name: 'websiteQuotationPage', component: WebsiteQuotationPage },
        { path: 'product/quotaion/list/page', name: 'quotationList', component: QuotationList},
        {
          path: '/about', component: About,
          children: [
            { path: 'about_us', component: AboutUs },
            { path: 'faq', component: FAQ },
            { path: 'how_2_buy', component: H2B },
            { path: 'privacy_statement', component: PS },
            { path: 'return_change', component: Return },
            { path: 'contact', component: Contact },
          ],
        },
      ],
    },
    { path: '/quotation/page/:urlSuffix', component: QuotationPage },
    { path: '/quotation/page/:qrcode/:urlSuffix', component: QuotationPage },
    { path: '/account', component: SimpleEntry,
      children: [
        { path: 'register', name:'register', component: RegisterSteps},
        { path: 'forgetpassword', name:'forgetPassword', component: ForgetPassword},
        { path: 'wechatlogin', name:'wechatLogin', component: WechatLogin}
      ]
    },
    { path: '/wechat', component: WechatEntry,
      children: [
        { path: 'search/bike', name:'SearchByBikeForWechat', component: SearchByBikeForWechat},
        { path: 'search/name', name:'SearchByNameForWechat', component: SearchByNameForWechat},
      ]
    }
  ]
});

Vue.use(VueGtag, {
  config: { id: "G-78TZH6L2PV" }
}, router);

//页面跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})


////////////////////
// axios
////////////////////
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

// 只有这里有环境区分，未来多了要单独抽取config.js
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/c/api';
  axios.defaults.withCredentials = false;
} else if(process.env.NODE_ENV === 'none'){
  axios.defaults.baseURL = 'http://127.0.0.1:5060/local/c/api';
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:5060/local/c/api'; // local开发
  // axios.defaults.baseURL = 'https://www.mobag.cn/dev/c/api';
}


////////////////////
// i18n
////////////////////
const i18n = new VueI18n({
  messages: I18nMessage,
  locale: 'cn',
});

export default new Vue({
  el: '#mobag_web',
  router,
  i18n,
  render: h => h(App)
});

