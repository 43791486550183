<template>
  <modal :name="name" width="90%" height="90%">
    <div class="modal-container">
      <div class="modal-title">
        <a>筛选</a>
      </div>
      <div class="modal-body">
        <div class="seg" v-for="tag in myTagList" :key="tag.id">
          <b-button v-b-toggle="tag.name"
                    variant="outline-primary"
                    class="cat-title">
            <span class='text'>{{tag.name}}</span>
            <v-icon v-if="tag.visible" name="minus" class="symble minus" width="1rem" height="1rem"/>
            <v-icon v-else name="plus" class="symble plus" width="1rem" height='1rem'/>
          </b-button>
          <b-collapse :id="tag.name" v-model="tag.visible">
            <b-card>
              <b-form-checkbox v-model="mySelectedTag"
                               class="card-text"
                               :key="option.id"
                               :value="option.id"
                               v-for="option in tag.children">
                {{ option.name }}
              </b-form-checkbox>
            </b-card>
          </b-collapse>
        </div>

        <div class="seg" v-for="brand in myBrandList" :key="brand.id">
          <b-button v-b-toggle="brand.name"
                    variant="outline-primary"
                    class="cat-title"
          >
            <span class='text'>{{brand.name}}</span>
            <v-icon v-if="brand.visible" name="minus" class="symble minus" width="1rem" height="1rem"/>
            <v-icon v-else name="plus" class="symble plus" width="1rem" height='1rem'/>
          </b-button>
          <b-collapse :id="brand.name" v-model="brand.visible">
            <div class="search-view">
              <input v-model="brandKeyword" placeholder="品牌搜索"/>
              <v-icon name="search" class="search-icon" ></v-icon>
            </div>
            <b-card>
              <b-form-checkbox
                v-model="mySelectedBrand"
                class="card-text"
                :key="option.id"
                :value="option.id"
                v-for="option in brandSearchList">
                {{ option.name }}
            </b-form-checkbox>
            </b-card>
          </b-collapse>
        </div>
        <div class="operation">
          <div class="reset"><a @click="reset()" class="font12">重置</a></div>
          <button-black
            :click="hide"
            text='完成'
            style="width:80px;"
          />
        </div>
      </div>
      <div class="modal_close">
        <a href="#" @click="hide"><v-icon class="close" name="times"/></a>
      </div>
    </div>
  </modal>
</template>

<script>
  import ButtonBlack from '../../components/mobile/ButtonBlack.vue'
  export default {
    props: ['modalName', 'tagList', 'brandList','selectedTag', 'selectedBrand'],
    components: {
      'button-black':ButtonBlack
    },
    watch: {
      tagList: function(newTagList) {
        this.myTagList = [];
        for (let tag of newTagList) {
          this.myTagList.push({
            id: tag.id,
            name: tag.name,
            visible: true,
            children: tag.children,
          })
        }
      },
      brandList: function(newBrandList) {
        this.myBrandList = [];
        for (let brand of newBrandList) {
          this.myBrandList.push({
            id: brand.id,
            name: brand.name,
            visible: true,
            children: brand.children,
          })
        }
      },
      selectedTag: function(newValue) {
        this.mySelectedTag = newValue;
      },
      selectedBrand: function(newValue) {
        this.mySelectedBrand = newValue;
      },
      mySelectedTag: function(newValue) {
        this.$emit('onTagSelectChange', newValue);
      },
      mySelectedBrand: function(newValue) {
        this.$emit('onBrandSelectChange', newValue);
      }
    },
    data() {
      return {
        name: this.modalName,
        visible: true,
        myTagList: [],
        myBrandList: [],
        mySelectedTag: this.selectedTag,
        mySelectedBrand: this.selectedBrand,
        brandKeyword: ''
      }
    },
    computed: {
      brandSearchList() {
        return this.myBrandList[0].children.filter((item)=>{
          return item.name.toLowerCase().includes(this.brandKeyword.toLowerCase())
        })
      }
    },
    methods: {
      hide () {
        this.$modal.hide(this.name);
      },
      reset() {
        this.mySelectedBrand = []
        this.mySelectedTag=[];

      }
    }
  }
</script>

<style scoped lang="scss">
.modal-container {position: relative;}
.modal-title{
  border-bottom:1px solid #ececec;
  font-size:1.4rem;
  font-weight:600;
  padding: 18px 18px 14px;
}
.modal-body {
  padding: 0 20px 20px;
  overflow:scroll;
  height:90%;
}
.seg{border-bottom:1px solid #ececec; padding:12px;}
.modal_close a{ color: black}
.modal_close {position:absolute; top:18px; right:20px; width:50px; height:50px;}
.modal_close .close{font-size:14px;}
.operation{align-items:center; margin-top:20px; font-weight:600; color:#000; display:flex}
.operation a:active{opacity:0.6; font-size:1.2rem; }
.reset{flex:1; margin-left:10px;}

.btn-outline-primary,
.btn-outline-primary:focus, .btn-outline-primary.focus,
.btn-outline-primary:hover, .btn-outline-primary.hover,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus
{color: #333; border:0; box-shadow:none; background-color:transparent}
.btn-outline-primary:hover, .btn-outline-primary.hover {color: #ec2f00}
.card-text {padding-top:0.5rem; padding-left:1.1rem}
.card{border:0; background:transparent;box-shadow: none}
.cat-title {font-size:1.4rem; display:flex; width:100%; text-align:left; padding:0}
.symble {width:20px; align-self:center}
.text {flex:1;}
.card-body {padding-top:0.6rem; font-size:1.2rem; line-height:2.4rem}
.cat-container {margin-bottom:1rem}
.cat-block {margin-bottom:4 rem}
.custom-control-label {font-size:1.2rem}
.search-view {
  position: relative;
  margin: 1rem 0 .2rem;
  color: #333;
  input {
    border: 1px solid #c7c7c6;
    outline: none;
    display: block;
    font-size: 1.6rem;
    height: 4rem;
    padding: 0 3.5rem 0 1rem;
    line-height: 4rem;
    width: 100%;
  }
  .search-icon {
    position: absolute;
    right: 1rem;
    top: 1.1rem;
    cursor: pointer;
  }
}
</style>

