var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("modal", { attrs: { name: _vm.name, width: 550, height: 510 } }, [
    _c("div", { staticClass: "modal-container" }, [
      _c("div", { staticClass: "modal-title" }, [
        _c("h2", { staticClass: "en" }, [_vm._v("LOGIN")]),
        _vm._v(" "),
        _c("h2", { staticClass: "ch" }, [_vm._v("登录")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("b-form-input", {
            attrs: { type: "text", placeholder: "手机号" },
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = $$v
              },
              expression: "username"
            }
          }),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: { type: "password", placeholder: "密码" },
            model: {
              value: _vm.password,
              callback: function($$v) {
                _vm.password = $$v
              },
              expression: "password"
            }
          }),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "forget-password",
              attrs: { target: "_blank", to: { name: "forgetPassword" } }
            },
            [_vm._v("忘记密码?")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-btn btn-red",
              on: {
                click: function($event) {
                  return _vm.login()
                }
              }
            },
            [_c("a", { attrs: { href: "#" } }, [_vm._v("登录")])]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "split" }, [
            _c("div", { staticClass: "line" }),
            _vm._v(" "),
            _c("span", [_vm._v("OR")]),
            _vm._v(" "),
            _c("div", { staticClass: "line" })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "other" },
            [
              _c(
                "a",
                {
                  staticClass: "wechat-login",
                  on: {
                    click: function($event) {
                      return _vm.goWechatLogin()
                    }
                  }
                },
                [
                  _c("a", { staticClass: "logo", attrs: { href: "#" } }),
                  _vm._v(" "),
                  _c("a", { staticClass: "wechatStr", attrs: { href: "#" } }, [
                    _vm._v("微信登录")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "regist", attrs: { to: { name: "register" } } },
                [_vm._v("\n          注册\n        ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "modal_close" }, [
        _c(
          "a",
          { attrs: { href: "#" }, on: { click: _vm.hide } },
          [_c("v-icon", { staticClass: "close", attrs: { name: "times" } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }