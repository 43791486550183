<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <!--<img src="https://cdn.mobag.cn/953198c0ed93d3dd61d991d191c525d4" />-->
        <div class="title">
          <div class="bar"></div>
          <span class="en">RMA</span>
          <span class="ch">换货及退货</span>
        </div>
        <p>{{ $t("about.returnChange.text") }}</p>
        <p>
          <b>{{ $t("about.returnChange.contactor") }}</b><br>
          <img :src="sellQrCode" style="width: 12rem" alt="">
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import {
  getRandomSelQrCode
} from '../../common/Util';

export default {
  data: function() {
    return {
      sellQrCode: getRandomSelQrCode(),
    }
  },
  created() {
    window.document.title = '摩燃-换货及退货';
  }
}
</script>

<style scoped>
  .title {
    left: -150px;
  }
  @media (max-width:900px) {
    .introduction-bg {
      .title {
        left:0;
        top: -100px;
      }
    }
  }
</style>
