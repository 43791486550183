<template>
  <div class="footer-container">
    <div class="footer-left">
      <div class="sub-column">
        <h2>{{ $t("footer.instruction") }}</h2>
        <router-link to="/about/how_2_buy">{{ $t("footer.howToBuy") }}</router-link>
        <router-link to="/about/faq">{{ $t("footer.faq") }}</router-link>
        <!--li><a href="">支付</a></li-->
        <!--li><a href="">运送</a></li-->
        <router-link to="/about/return_change">{{ $t("footer.returnChange") }}</router-link>
      </div>
      <div class="sub-column">
        <h2>{{ $t("footer.company") }}</h2>
        <router-link to="/about/about_us">{{ $t("footer.aboutUs") }}</router-link>
        <!--li><a href="">办事处</a></li-->
        <!--li><a href="">商店</a></li-->
        <router-link to="/about/contact">{{ $t("footer.contactUs") }}</router-link>
      </div>
      <div class="sub-column">
        <h2>{{ $t("footer.policies") }}</h2>
        <router-link to="/about/privacy_statement">{{ $t("footer.privacyStatement") }}</router-link>
      </div>
    </div>

    <div class="table-list">
      <b-button v-b-toggle.guid>
        <span class="title">{{ $t("footer.instruction") }}</span>
        <span class="when-opened">
          <v-icon name="angle-up" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
        <span class="when-closed">
          <v-icon name="angle-down" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
      </b-button>
      <b-collapse visible id="guid">
        <router-link to="/about/how_2_buy">{{ $t("footer.howToBuy") }}</router-link>
        <router-link to="/about/faq">{{ $t("footer.faq") }}</router-link>
        <router-link to="/about/return_change">{{ $t("footer.returnChange") }}</router-link>
      </b-collapse>

      <b-button v-b-toggle.company>
        <span class="title">{{ $t("footer.company") }}</span>
        <span class="when-opened">
          <v-icon name="angle-up" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
        <span class="when-closed">
          <v-icon name="angle-down" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
      </b-button>
      <b-collapse visible id="company">
        <router-link to="/about/about_us">{{ $t("footer.aboutUs") }}</router-link>
        <router-link to="/about/contact">{{ $t("footer.contactUs") }}</router-link>
      </b-collapse>

      <b-button v-b-toggle.policy>
        <span class="title">{{ $t("footer.policies") }}</span>
        <span class="when-opened">
          <v-icon name="angle-up" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
        <span class="when-closed">
          <v-icon name="angle-down" scale="1.1" color="#CED4DA" class="icon"/>
        </span>
      </b-button>
      <b-collapse visible id="policy">
        <router-link to="/about/privacy_statement">{{ $t("footer.privacyStatement") }}</router-link>
      </b-collapse>
    </div>
    <div class="icp_txt"><a href="https://beian.miit.gov.cn/" target="_blank" style="color:#ddd">京ICP备19004784号-1</a></div>

  </div>


</template>

<script>
  export default {}
</script>

<style scoped>
  .icp_txt { font-size:1.2rem;  height:40px;  color: #555; padding-top:80px; padding-left:16px; max-width:1200px; margin:0 auto;}
  .footer-container { background-color: black;height:403px;color: white; }
  .footer-left {
    display:flex;
    max-width:1200px;
    margin:0 auto;
    padding-left:16px;
    padding-top:30px;
    .sub-column {
      display: block; margin-right:100px;
      a {display: block}
    }
    h2{ color: white; font-size:1.6rem; line-height:4rem}
    a{ color: white; opacity:0.6; font-size:1.4rem; line-height:3.6rem}
  }
  .table-list {display: none;}
  @media screen and (max-width: 575px) {
    .table-list {display: block; padding: 9px 16px;}
    .footer-left {display: none;}
    .icp_txt{padding-left:24px;}
    .table-list{
      button {
        display:flex;
        background: transparent;
        border:0;
        width:100%;
        text-align:left;
        span { font-size:1.4rem; display:inline-block; color:#fff}
      }
      .title {flex:1; color: #fff; margin-bottom:0}
      a {color: #555; display: block; padding: 6px 8px 0; font-size:1.2rem}
      .collapse {padding-bottom:10px;}
      .icon{justify-self: flex-end}
      .collapsed > .when-opened,
      :not(.collapsed) > .when-closed {
        display: none;
      }
    }
  }
  /* bootstrap default btn style change*/
  .btn-secondary, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:hover,
  .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border:0;
    box-shadow: 0 0 0 0;
    color:white;
  }
</style>
