var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    {
      staticStyle: {
        "max-width": "1200px",
        padding: "10px 0",
        margin: "10px auto"
      },
      attrs: { fluid: "" }
    },
    [
      _c(
        "b-row",
        { staticClass: "flex row-container" },
        [
          _c("div", { staticClass: "filter", attrs: { id: "webFilter" } }, [
            _c(
              "div",
              { staticClass: "cat-container" },
              [
                _c("h2", [_vm._v("商品分类")]),
                _vm._v(" "),
                _c("webFilter", {
                  attrs: {
                    list: _vm.tagList,
                    collaped: 1,
                    selected: _vm.selectedTagIdList
                  },
                  on: { onSelectChange: _vm.onTagSelectChange }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cat-container" },
              [
                _c("h2", [_vm._v("品牌筛选")]),
                _vm._v(" "),
                _c("webFilter", {
                  attrs: {
                    list: _vm.brandList,
                    collaped: 0,
                    selected: _vm.selectedBrandIdList
                  },
                  on: { onSelectChange: _vm.onBrandSelectChange }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("mobileFilter", {
            attrs: {
              modalName: _vm.filterModal,
              tagList: _vm.tagList,
              brandList: _vm.brandList,
              selectedTag: _vm.selectedTagIdList,
              selectedBrand: _vm.selectedBrandIdList
            },
            on: {
              onBrandSelectChange: _vm.onBrandSelectChange,
              onTagSelectChange: _vm.onTagSelectChange
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "result-list" }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.props.searchInfo != null,
                    expression: "props.searchInfo != null"
                  }
                ],
                staticClass: "search-bread"
              },
              [_vm._v(_vm._s(_vm.props.searchInfo))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "mobag-search" }, [
              _c(
                "div",
                { staticClass: "width-for-search" },
                [
                  _c("h2", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t("searchByBike.title")))
                  ]),
                  _vm._v(" "),
                  _c("search-by-bike")
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mobile-filter" },
              [
                _c("buttonOrange", {
                  staticStyle: { width: "100%" },
                  attrs: { click: _vm.openFilterModal, text: "筛选" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-result-list" },
              [
                _c(
                  "h2",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isProductListEmpty,
                        expression: "isProductListEmpty"
                      }
                    ],
                    staticStyle: {
                      "margin-left": "20px",
                      "font-size": "1.4rem"
                    }
                  },
                  [_vm._v("很抱歉，没有搜索到商品")]
                ),
                _vm._v(" "),
                _c(
                  "b-container",
                  { staticStyle: { padding: "0" }, attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      { staticStyle: { margin: "0" } },
                      _vm._l(_vm.productList, function(product) {
                        return _c(
                          "b-col",
                          {
                            key: product.id,
                            attrs: { cols: "6", sm: "6", md: "6", lg: "4" }
                          },
                          [
                            _c("product-item", {
                              key: product.id,
                              attrs: { product: product }
                            })
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pageTotal > 1,
                    expression: "pageTotal > 1"
                  }
                ],
                staticClass: "overflow-auto web"
              },
              [
                _c("b-pagination-nav", {
                  staticStyle: { "margin-top": "30px" },
                  attrs: {
                    "link-gen": _vm.linkGen,
                    limit: "6",
                    "number-of-pages": _vm.pageTotal || 1,
                    align: "center",
                    size: "lg",
                    value: _vm.currentPage
                  },
                  on: { change: _vm.onPageNumberChange }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }