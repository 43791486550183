var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "padding-bottom": "50px" } },
    [
      _c(
        "div",
        {
          staticClass: "top-container",
          staticStyle: { "text-align": "center" }
        },
        [
          _c("p", { staticClass: "title-size" }, [
            _vm._v(_vm._s(_vm.pageTitle))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-list" },
        [
          _c("b-table", {
            staticStyle: { "text-align": "center", "font-size": "16px" },
            attrs: {
              items: _vm.pageList,
              fields: _vm.fields,
              emptyText: _vm.emptyText,
              striped: "",
              "show-empty": ""
            },
            scopedSlots: _vm._u([
              {
                key: "cell(shareImage)",
                fn: function(data) {
                  return [
                    _c("div", [
                      _c(
                        "a",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: {
                            click: function($event) {
                              return _vm.linkToQuotationPage(
                                data.item.urlSuffix
                              )
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "80px" },
                            attrs: {
                              src: _vm.operaImg(data.item.shareImage),
                              onerror:
                                "this.src='https://cdn.mobag.cn/9dc79963d75b6b8785c47caf8de091a3?imageView2/2/w/100/h/100'"
                            }
                          })
                        ]
                      )
                    ])
                  ]
                }
              },
              {
                key: "cell(title)",
                fn: function(data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "center" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "websiteQuotationPage",
                                params: { urlSuffix: data.item.urlSuffix }
                              }
                            }
                          },
                          [_vm._v(_vm._s(data.item.title))]
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "cell(shareDescription)",
                fn: function(data) {
                  return [
                    _c("div", { staticClass: "center" }, [
                      _c(
                        "p",
                        {
                          on: {
                            click: function($event) {
                              return _vm.linkToQuotationPage(
                                data.item.urlSuffix
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(data.item.shareDescription))]
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("img-enlarged", {
        ref: "imgEnlarged",
        on: {
          tangle: function($event) {
            return _vm.tangleImgEnlarged()
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-list-mobile" },
        [
          _vm._l(_vm.pageList, function(item, index) {
            return _c("mobile-list-item", {
              key: index,
              staticClass: "mobileItems",
              attrs: { item: item }
            })
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.pageList.length === 0,
                  expression: "pageList.length===0"
                }
              ],
              staticClass: "emptyForMobile",
              staticStyle: { "font-size": "1.2rem" }
            },
            [_vm._v(_vm._s(_vm.emptyText))]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }