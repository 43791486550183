<template>
  <b-button
          class="f12 fw600"
          @click="click"
          :disabled="disabled"
  >
    <v-icon
            v-if="iconName && iconName.length > 0"
            :name="iconName"
            style="height:12px"
    />{{text}}
  </b-button>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: 'OK',
    },
    click: {
      type: Function,
      default() {},
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
  button, button:hover, button:disabled, button:focus, button:active{
    min-width:68px;
    min-height:28px;
    border-radius:0;
    border:1px solid #fa4c06;
    background-color:#fa4c06;
    color:#fff;
    font-size:1.2rem;
    line-height:1.4rem;
    font-weight:600;
    outline: 0;
    box-shadow: none;
    svg {margin-bottom:3px; margin-right:6px}
  }

  .btn:not(:disabled):not(.disabled):active,
  .btn:not(:disabled):not(.disabled).active {
    box-shadow: none;
    border:1px solid #fa4c06;
    color: #fff;
    background-color: #fa4c06;
    outline: 0;
    opacity: 0.6;
  }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #ccc; color: #fff; border:0
  }
</style>
