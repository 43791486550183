var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "introduction-bg" }, [
      _c(
        "div",
        { staticClass: "dialog-bg" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.$t("about.privacyStatement"), function(text) {
            return _c("p", [_vm._v(_vm._s(text))])
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "placeholder" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "en" }, [_vm._v("Privacy")]),
      _vm._v(" "),
      _c("span", { staticClass: "ch" }, [_vm._v("隐私声明")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }