var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "f12 fw600",
      attrs: { disabled: _vm.disabled },
      on: { click: _vm.click }
    },
    [
      _vm.iconName && _vm.iconName.length > 0
        ? _c("v-icon", {
            staticStyle: { height: "12px" },
            attrs: { name: _vm.iconName }
          })
        : _vm._e(),
      _vm._v(_vm._s(_vm.text) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }