<template>
  <div>
    <v-dialog />
    <simple-header></simple-header>
    <router-view></router-view>
  </div>
</template>

<script>
  import SimpleHeader from '../templates/SimpleHeader.vue'
  export default {
    data: function() {
      return {
      }
    },
    components: {
      'simple-header': SimpleHeader,
    },
  }
</script>
