<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div class="mobile" :class="[!tangleLoginModal ? 'close' : '']">
    <MobileHeader @close="close()"></MobileHeader>
    <div class="modal-container">
      <div class="modal-title">
        <h2 class="ch">登录</h2>
      </div>
      <div class="modal-body">
        <b-form-input type="text" placeholder="手机号" v-model="username"></b-form-input>
        <b-form-input type="password" placeholder="密码" v-model="password"></b-form-input>
        <div class="modal-btn btn-red" @click="login()">
          <a href="#" >登录</a>
        </div>
        <router-link class="forget-password" target="_blank" :to="{name: 'forgetPassword'}">忘记密码?</router-link>

        <div class="split">
          <div class="line"></div>
          <span>OR</span>
          <div class="line"></div>
        </div>
        <div class="other">
          <router-link class="regist" :to="{name: 'register'}">
            注册
          </router-link>
        </div>
      </div>

      <div class="modal_close">
        <a href="#" @click="hide"><v-icon class="close" name="times"/></a>
      </div>
    </div>
  </div>
</template>

<script>
  import MobileHeader from './public/HeaderWhite.vue'
  import { baseUrl } from '../../util.js'

  export default {
    components: {
      MobileHeader
    },
    data: function() {
      return {
        tangleLoginModal: false,
        searchText: '',
        username:'',
        password: '',
      }
    },
    methods: {
      close: function() {
        this.tangleLoginModal = false;
        this.$emit('tangle');
      },
      handleParentClick: function(st) {
        this.tangleLoginModal =  st
      },
      linkToDealer() {
        window.location.href = baseUrl.dealer;
      },
      hide () {
        this.$modal.hide(this.name);
      },
      login() {
        if(this.username && this.password && !this.isLoginClick) {
          this.isLoginClick = true;
          let self = this;
          this.$api.post(`/login`, {phone: this.username, password: this.password})
            .then(data => {
              self.isLoginClick = false;
              if(data.code !== 0) {
                this.$toast.error(data.msg);
                return;
              }
              this.$emit('loginCallBack');
              this.close();
              if(this.$route.matched[0].path === '/account') {
                this.$router.push({name: 'index'})
              }
            }, (err) => {
              this.$toast.error('网络错误，请稍后重试');
              self.isLoginClick = false;
            });
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .mobile {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:#FAFAFA;
    z-index:9;
  }
  .close {
    display:none
  }
  a{font-size: 1.4rem; display: block; line-height:48px; padding-left:16px;}
  a:hover { text-decoration: none;}
  a:active { color: #ec2f00}

  .modal-container{margin:20px;}
  .modal-title{color: #000; font-size:1.6rem; border-bottom:1px solid #eee}
  .modal-body {
    padding: 20px 0 0 0;
    a:hover{text-decoration: underline}
    input {border: 1px solid #ccc; height:50px; width: 100%; font-size:1.4rem; margin:0 0 12px;}
    .forget-password {
      font-size:1.2rem;
      color: #000;
      text-align:center;
      display:block;
      margin: 12px 0 0;
      line-height:2.4rem;
      padding-left:0;
    }
    .forget-password:hover {color: #000; }
    .split {
      display:flex; margin-top:30px; margin-bottom:4px;
      .line {width: 40%; border-top:1px solid #000; flex:1}
      span {display: block; color: #000; font-size:1.8rem; font-family:'teko'; width:40px; margin-top:-12px; text-align:center}
    }
  }
  .modal-btn {
    height:44px; text-align:center; cursor:pointer;
    a {color: white; line-height:44px; font-size:1.6rem; font-weight:600;}
  }
  .modal-btn:hover {
    text-decoration: underline;
  }
  .modal-btn:active {
    color: #ccc; opacity: 0.6
  }
  .btn-red {
    background-color:#ec2f00;
    margin-top:28px;
  }
  .other {
    display:flex;
    align-items:center;
    .wechat-login {
      flex:2; display:flex; align-items: center;
      .logo {
        width:40px;
        height:40px;
        background:url('https://cdn.mobag.cn/015360329555b0444e9b30f3bbbc6e71') 0 0 no-repeat;
        background-size:contain;
        border-radius:50%;
        display:block
      }
      .wechatStr {margin-left:20px; align-items:left; font-size:1.4rem}
    }
    .regist{
      width:140px; flex:1; justify-content: center; height:44px; background-color:black;
      display:flex;align-items: center;color:white; font-size:1.6rem; font-weight:600;
    }
    .flex1{flex:1}
    .regist:active{ opacity: 0.7;}
  }
</style>
