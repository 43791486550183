<template>
  <div style="background-color:#fafafa;">
    <div class="main">

      <div class="mt80" id="step1" v-show='currentStep===1'>
        <b-input-group prepend="请输入手机号" class="register">
          <!--          <b-form-input id="countryCode" placeholder="+86" aria-label="First name" class="country-code border1" v-model="countryCode"></b-form-input>-->
          <b-form-input
                  id='phone'
                  class="border3"
                  v-model="phone"
                  @change="onPhoneChange($event)"
          />
        </b-input-group>

        <b-input-group prepend="手机验证码" class="mt20 register">
          <b-form-input class="border2" v-model="smsCode" id="smsCode"></b-form-input>
          <b-input-group-append>
            <b-button
                    placeholder="请输入验证码"
                    class="btn-send-code font12"
                    style="border-radius:4px; width:108px"
                    @click="sendSMSCode()"
            >
              {{smsStr}}
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="button-lg-red mt70" @click="goSetPassword()">
          <a href="#">下一步</a>
        </div>
      </div>

      <div class="mt80" id="step2" v-show="currentStep===2">
        <b-input-group prepend="设置密码" class="mt20 register">
          <b-form-input
                  type="password"
                  id="password"
                  class="border3"
                  placeholder="建议使用两种或以上的字符"
                  v-model="password"
          />
        </b-input-group>
        <b-input-group prepend="确认密码" class="mt20 register">
          <b-form-input
                  type="password"
                  id="rePassword"
                  class="border3"
                  placeholder="请再次输入密码"
                  v-model="rePassword"
          />
        </b-input-group>
        <div class="button-lg-red mt70" @click="goFinish()">
          <a href="#" >下一步</a>
        </div>
      </div>

      <div class="mt120" id="step3" v-show="currentStep===3">
        <div class="tips">
          <v-icon name="check-circle" />
          <div class="mt20">修改成功</div>
          <router-link class="smallTxt" to="/">点击这里回到首页</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  const defaultSmsStr = '获取验证码';
  const defaultSmsCount = 60;
  export default {
    data() {
      return {
        smsStr: defaultSmsStr,
        sec: defaultSmsCount,
        isSmsSending: false,
        timer: null,
        currentStep: 1,
        smsCode: null,
        username: null,
        password:null,
        rePassword: null,
        phone: null,
        isGoSetPasswordClick: false,
        isGoFinishClick: false,
      }
    },
    watch: {
      sec: function (val) {
        if (val === -1) {
          this.timer = clearInterval(this.timer);
          this.isSmsSending = false;
          this.smsStr = defaultSmsStr;
          this.sec = defaultSmsCount;
        }
      }
    },
    methods: {
      onPhoneChange(value) {
        this.phone = value.replace(/[^\0-9]/g, '')
      },
      sendSMSCode() {
        if(!this.phone || this.isSmsSending || (this.timer && this.sec < defaultSmsCount)) {
          return;
        }
        let self = this;

        this.isSmsSending = true;
        this.timer = setInterval(function(){
          self.sec --;
          self.smsStr = '重新发送（' + self.sec + 's）'
        }, 1000)

        this.$api.post(`/forget_pwd_send_sms_code`, {phone: this.phone})
          .then(data => {
            self.isSmsSending = false;
            if(data.code === 0 && data.msg === 'OK') {
              console.log('send sms code successfully')
            }
            else {
              self.$toast.error(data.msg)
            }
          }, (err) => {
            console.log('send sms code failed');
            self.$toast.error(err.msg);
            self.isSmsSending = false;
          });
      },

      goSetPassword() {
        if(this.isGoSetPasswordClick || !this.checkStep1()) {
          return;
        }
        this.isGoSetPasswordClick = true
        let self = this;
        this.$api.post(`/forget_pwd_auth_sms_code`, {phone: this.phone, smsCode: this.smsCode})
          .then(data => {
            self.isGoSetPasswordClick = false;
            if(data.code !== 0) {
              self.$toast.error(data.msg);
              return;
            }
            this.currentStep = 2;
          }, (err) => {
            self.$toast.error(err.msg);
            self.isGoSetPasswordClick = false;
          });
      },

      goFinish(){
        if(this.isGoFinishClick || !this.checkStep2()) {
          return;
        }
        this.isGoFinishClick = true;
        let self = this;
        this.$api.post(`/forget_pwd_reset_pwd`, {
          phone: this.phone,
          smsCode: this.smsCode,
          password: this.password,
          passwordRepeat: this.rePassword
        })
          .then(data => {
            self.isGoFinishClick = false;
            if(data.code !== 0) {
              self.$toast.error(data.msg);
              return;
            }
            this.currentStep = 3;
          }, (err) => {
            self.$toast.error(err.msg);
            self.isGoFinishClick = false;
          });
      },

      checkStep1() {
        // let elCountryCode = document.getElementById('countryCode');
        let elPhone = document.getElementById('phone');
        let elSmsCode = document.getElementById('smsCode');
        // elCountryCode.removeAttribute('style')
        elPhone.removeAttribute('style')
        elSmsCode.removeAttribute('style')
        // if(!this.countryCode) {
        //   elCountryCode.setAttribute('style', 'border-color:#ec2f00')
        // }
        if(!this.phone) {
          elPhone.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.smsCode) {
          elSmsCode.setAttribute('style', 'border:1px solid #ec2f00')
        }
        // return !(!this.phone || !this.smsCode || !this.countryCode);
        return !(!this.phone || !this.smsCode);
      },
      checkStep2() {
        let elPassword = document.getElementById('password');
        let elRePassword = document.getElementById('rePassword');
        elPassword.removeAttribute('style')
        elRePassword.removeAttribute('style')
        if(!this.password) {
          elPassword.setAttribute('style', 'border:1px solid #ec2f00')
        }
        if(!this.rePassword) {
          elRePassword.setAttribute('style', 'border:1px solid #ec2f00')
        }
        return !(!this.password || !this.rePassword);
      }
    }
  }
</script>

<style scoped>
  .main {width:90%; max-width:400px; margin: 100px auto;}
  .steps-process {
    display:flex;
    align-items:center;
    .item{width:80px; text-align:center}
    .circle {
      background-color: white;
      border: solid 1px #000;
      width:38px;
      height:38px;
      display:block;
      margin: 0 auto 4px;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      span{color: black;font-family: 'teko'; font-size:2.6rem; margin-top:4px;}
    }
    span {margin-top:8px;}
    .current {
      background-color: black;
      border: solid 1px #fff;
      span{color: white;}
    }
    .line {flex:1; border-bottom:1px dashed #000; height:1px; margin:0 0 20px}
  }
  .country-code{width:78px; flex:none; margin-right:10px;}
  .border1{border:1px solid #ccc;}
  .borderRed{border:1px solid #red}
  .border2{
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    background-color: #fff;
  }
  .border3{
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
    background-color: #fff;
  }
  .register .input-group-text {
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-left:1px solid #ccc;
    background-color: #fff;
    width:90px;
    justify-content: center;
    font-size:1.2rem
  }
  .btn-send-code {
    background-color: #f1f1f1;
    color: #333;
    margin:5px;
  }
  .input-group-append {
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
    background-color:#fff
  }
  .input-group .form-control {height:50px;}
  .tips {text-align:center; font-size:1.8rem; color: #999}

  .item svg {display:none}
  .item .passed svg {color: #000; display:inline-block; margin-top:10px; text-align:center;}
  .item .passed span {display:none}
  .tips svg {display:block; color:#1bb934; width: 80px; height:80px; margin:0 auto;}
  .smallTxt {font-size:1.2rem; text-decoration:underline; display:block; margin-top:10px;}
  @media (max-width:767px){
  }

  @media (max-width: 575px) {
  }
</style>
