import { render, staticRenderFns } from "./SimpleHeader.vue?vue&type=template&id=13d01aae&scoped=true&xmlns%3Av-bind=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./SimpleHeader.vue?vue&type=script&lang=js&"
export * from "./SimpleHeader.vue?vue&type=script&lang=js&"
import style0 from "./SimpleHeader.vue?vue&type=style&index=0&id=13d01aae&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d01aae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangyu/Projects/Mobag/mobag/mobag_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13d01aae')) {
      api.createRecord('13d01aae', component.options)
    } else {
      api.reload('13d01aae', component.options)
    }
    module.hot.accept("./SimpleHeader.vue?vue&type=template&id=13d01aae&scoped=true&xmlns%3Av-bind=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&", function () {
      api.rerender('13d01aae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/templates/SimpleHeader.vue"
export default component.exports