var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _c("div", { staticClass: "left" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.callParentFunc(_vm.item.image)
            }
          }
        },
        [_c("img", { attrs: { src: _vm.operaImg(_vm.item.image) } })]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "right" }, [
      _c(
        "a",
        {
          on: {
            click: function($event) {
              return _vm.callParentFunc(_vm.item.image)
            }
          }
        },
        [
          _c("div", { staticClass: "price" }, [
            _c("span", [_vm._v(_vm._s(_vm.item.description))])
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "price" }, [
          _c("span", [_vm._v("品牌：" + _vm._s(_vm.item.brand))]),
          _vm._v(" "),
          _c("span", { staticStyle: { "margin-left": "10px" } }, [
            _vm._v("编号：" + _vm._s(_vm.item.partNumber))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "price" }, [
        _c("div", { staticClass: "money" }, [
          _c("div", [
            _c("span", [
              _vm._v(
                "建议零售价 " + _vm._s(_vm.item.currentPrice / 100) + " 元"
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }