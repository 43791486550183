var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cat-block" },
    _vm._l(_vm.catList, function(cat, index) {
      return _c(
        "div",
        { key: cat.id, staticClass: "cat-container" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle",
                  value: cat.name,
                  expression: "cat.name"
                }
              ],
              staticClass: "cat-title",
              attrs: { variant: "outline-primary" }
            },
            [
              _c("span", { staticClass: "text" }, [_vm._v(_vm._s(cat.name))]),
              _vm._v(" "),
              cat.visible
                ? _c("v-icon", {
                    staticClass: "symble",
                    attrs: { name: "minus", width: "1rem", height: "1rem" }
                  })
                : _c("v-icon", {
                    staticClass: "symble",
                    attrs: { name: "plus", width: "1rem", height: "1rem" }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              class: [
                { "brand-collapse": cat.name == "品牌" },
                { "brand-collapse-down": _vm.scrollFlag }
              ],
              attrs: { id: cat.name },
              model: {
                value: cat.visible,
                callback: function($$v) {
                  _vm.$set(cat, "visible", $$v)
                },
                expression: "cat.visible"
              }
            },
            [
              cat.name == "品牌"
                ? _c(
                    "div",
                    { staticClass: "search-view" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.brandKeyword,
                            expression: "brandKeyword"
                          }
                        ],
                        attrs: { placeholder: "品牌搜索" },
                        domProps: { value: _vm.brandKeyword },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.brandKeyword = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("v-icon", {
                        staticClass: "search-icon",
                        attrs: { name: "search" }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-card",
                {
                  ref: "box" + cat.id,
                  refInFor: true,
                  class: [
                    { "brand-box": cat.name == "品牌" },
                    { "brand-box-down": _vm.scrollFlag }
                  ],
                  on: {
                    scroll: function($event) {
                      return _vm.scrollHandler()
                    }
                  }
                },
                _vm._l(_vm.getChildren(cat), function(option) {
                  return _c(
                    "b-form-checkbox",
                    {
                      key: option.id,
                      staticClass: "card-text",
                      attrs: { value: option.id },
                      model: {
                        value: _vm.mySelected,
                        callback: function($$v) {
                          _vm.mySelected = $$v
                        },
                        expression: "mySelected"
                      }
                    },
                    [
                      _vm._v(
                        "\n                         " +
                          _vm._s(option.name) +
                          "\n        "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }