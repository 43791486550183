var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-container" }, [
    _c("div", { staticClass: "footer-left" }, [
      _c(
        "div",
        { staticClass: "sub-column" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("footer.instruction")))]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/how_2_buy" } }, [
            _vm._v(_vm._s(_vm.$t("footer.howToBuy")))
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/faq" } }, [
            _vm._v(_vm._s(_vm.$t("footer.faq")))
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/return_change" } }, [
            _vm._v(_vm._s(_vm.$t("footer.returnChange")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sub-column" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("footer.company")))]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/about_us" } }, [
            _vm._v(_vm._s(_vm.$t("footer.aboutUs")))
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/contact" } }, [
            _vm._v(_vm._s(_vm.$t("footer.contactUs")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sub-column" },
        [
          _c("h2", [_vm._v(_vm._s(_vm.$t("footer.policies")))]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: "/about/privacy_statement" } }, [
            _vm._v(_vm._s(_vm.$t("footer.privacyStatement")))
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table-list" },
      [
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.guid",
                modifiers: { guid: true }
              }
            ]
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("footer.instruction")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-opened" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-up", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-down", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { visible: "", id: "guid" } },
          [
            _c("router-link", { attrs: { to: "/about/how_2_buy" } }, [
              _vm._v(_vm._s(_vm.$t("footer.howToBuy")))
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/about/faq" } }, [
              _vm._v(_vm._s(_vm.$t("footer.faq")))
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/about/return_change" } }, [
              _vm._v(_vm._s(_vm.$t("footer.returnChange")))
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.company",
                modifiers: { company: true }
              }
            ]
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("footer.company")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-opened" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-up", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-down", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { visible: "", id: "company" } },
          [
            _c("router-link", { attrs: { to: "/about/about_us" } }, [
              _vm._v(_vm._s(_vm.$t("footer.aboutUs")))
            ]),
            _vm._v(" "),
            _c("router-link", { attrs: { to: "/about/contact" } }, [
              _vm._v(_vm._s(_vm.$t("footer.contactUs")))
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-button",
          {
            directives: [
              {
                name: "b-toggle",
                rawName: "v-b-toggle.policy",
                modifiers: { policy: true }
              }
            ]
          },
          [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("footer.policies")))
            ]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-opened" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-up", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("v-icon", {
                  staticClass: "icon",
                  attrs: { name: "angle-down", scale: "1.1", color: "#CED4DA" }
                })
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "b-collapse",
          { attrs: { visible: "", id: "policy" } },
          [
            _c("router-link", { attrs: { to: "/about/privacy_statement" } }, [
              _vm._v(_vm._s(_vm.$t("footer.privacyStatement")))
            ])
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icp_txt" }, [
      _c(
        "a",
        {
          staticStyle: { color: "#ddd" },
          attrs: { href: "https://beian.miit.gov.cn/", target: "_blank" }
        },
        [_vm._v("京ICP备19004784号-1")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }