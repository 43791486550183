var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "searchContainer" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchKeyword,
            expression: "searchKeyword"
          }
        ],
        staticClass: "wechatSearchInput",
        attrs: { type: "text", placeholder: "输入商品名称或配件编号" },
        domProps: { value: _vm.searchKeyword },
        on: {
          keydown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.search()
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchKeyword = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("btn-orange", {
        staticClass: "search",
        attrs: { click: _vm.search, text: "搜索" }
      }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "link",
          staticStyle: { "font-size": "16px" },
          on: {
            click: function($event) {
              return _vm.linkToSearchByBike()
            }
          }
        },
        [_vm._v("按车型搜索")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }