<template>
  <div class="top-bar">
    <div @click="singleClose()"><v-icon class="icon-close" name="times"/></div>
  </div>
</template>

<script>
  export default {
    methods:{
      singleClose: function() {
        this.$emit('close')
      }
    }
  }
</script>

<style scoped lang="scss">
.top-bar {
  background-color: black;
  color:white;
  height:48px;
  padding:16px;
  font-size:1.6rem;
}
.icon-close{
  &:active {color: #666}
}
</style>