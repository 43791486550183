var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "introduction-bg" }, [
      _c("div", { staticClass: "dialog-bg" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm._m(1),
            _vm._v(" "),
            _vm._l(_vm.$t("about.howToBuy"), function(text) {
              return _c("p", [_c("b", [_vm._v(_vm._s(text))])])
            })
          ],
          2
        ),
        _vm._v(" "),
        _c("img", {
          staticStyle: { width: "12rem" },
          attrs: { src: _vm.sellQrCode, alt: "" }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "placeholder" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "en" }, [_vm._v("Purchase")]),
      _vm._v(" "),
      _c("span", { staticClass: "ch" }, [_vm._v("如何购买")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("申请成为经销商："),
      _c(
        "a",
        { attrs: { href: "https://dealer.mobag.cn", target: "_blank" } },
        [_vm._v("经销商购买")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }