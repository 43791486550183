var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-bar" }, [
    _c(
      "div",
      {
        on: {
          click: function($event) {
            return _vm.singleClose()
          }
        }
      },
      [_c("v-icon", { staticClass: "icon-close", attrs: { name: "times" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }