var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-dialog"),
      _vm._v(" "),
      _c("web-header"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _c("web-footer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFooter,
            expression: "showFooter"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }