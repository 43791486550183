<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <!--<img src="https://cdn.mobag.cn/953198c0ed93d3dd61d991d191c525d4" />-->
        <div class="title">
          <div class="bar"></div>
          <span class="en">About Us</span>
          <span class="ch">关于我们</span>
        </div>

        <p v-for="text in $t('about.aboutUs.infoList')">{{ text }}</p>

        <p>
          <b v-for="text in $t('about.aboutUs.vision')">{{ text }}<br></b>
        </p>

        <p class="right light-grey font18">
        {{ $t('about.aboutUs.signature') }}
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
export default {
  created() {
    window.document.title = '摩燃-关于我们';
  }
}
</script>
