var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "background-color": "#fafafa" } }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "steps-process" }, [
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "circle",
              class: {
                current: _vm.currentStep === 1,
                passed: _vm.currentStep > 1
              }
            },
            [
              _c("span", [_vm._v("1")]),
              _vm._v(" "),
              _c("v-icon", { attrs: { name: "check" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [_vm._v("验证手机号")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "circle",
              class: {
                current: _vm.currentStep === 2,
                passed: _vm.currentStep > 2
              }
            },
            [
              _c("span", [_vm._v("2")]),
              _vm._v(" "),
              _c("v-icon", { attrs: { name: "check" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [_vm._v("填写账户信息")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "line" }),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "circle",
              class: { current: _vm.currentStep === 3 }
            },
            [
              _c("span", [_vm._v("3")]),
              _vm._v(" "),
              _c("v-icon", { attrs: { name: "check" } })
            ],
            1
          ),
          _vm._v(" "),
          _c("span", [_vm._v("注册成功")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 1,
              expression: "currentStep===1"
            }
          ],
          staticClass: "mt80",
          attrs: { id: "step1" }
        },
        [
          _c(
            "b-input-group",
            { staticClass: "register", attrs: { prepend: "请输入手机号" } },
            [
              _c("b-form-input", {
                staticClass: "border3",
                attrs: { id: "phone" },
                on: {
                  change: function($event) {
                    return _vm.onPhoneChange($event)
                  }
                },
                model: {
                  value: _vm.phone,
                  callback: function($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-input-group",
            { staticClass: "mt20 register", attrs: { prepend: "手机验证码" } },
            [
              _c("b-form-input", {
                staticClass: "border2",
                attrs: { id: "smsCode" },
                model: {
                  value: _vm.smsCode,
                  callback: function($$v) {
                    _vm.smsCode = $$v
                  },
                  expression: "smsCode"
                }
              }),
              _vm._v(" "),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-send-code font12",
                      staticStyle: { "border-radius": "4px", width: "108px" },
                      attrs: { placeholder: "请输入验证码" },
                      on: {
                        click: function($event) {
                          return _vm.sendSMSCode()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.smsStr) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "button-lg-red mt70",
              on: {
                click: function($event) {
                  return _vm.goDetail()
                }
              }
            },
            [_c("a", { attrs: { href: "#" } }, [_vm._v("下一步")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 2,
              expression: "currentStep===2"
            }
          ],
          staticClass: "mt80",
          attrs: { id: "step2" }
        },
        [
          _c(
            "b-input-group",
            { staticClass: "mt20 register", attrs: { prepend: "用户名" } },
            [
              _c("b-form-input", {
                staticClass: "border3",
                attrs: { id: "username", placeholder: "请设置您的用户登录名" },
                model: {
                  value: _vm.username,
                  callback: function($$v) {
                    _vm.username = $$v
                  },
                  expression: "username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-input-group",
            { staticClass: "mt20 register", attrs: { prepend: "设置密码" } },
            [
              _c("b-form-input", {
                staticClass: "border3",
                attrs: {
                  type: "password",
                  id: "password",
                  placeholder: "建议使用两种或以上的字符"
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-input-group",
            { staticClass: "mt20 register", attrs: { prepend: "确认密码" } },
            [
              _c("b-form-input", {
                staticClass: "border3",
                attrs: {
                  type: "password",
                  id: "rePassword",
                  placeholder: "请再次输入密码"
                },
                model: {
                  value: _vm.rePassword,
                  callback: function($$v) {
                    _vm.rePassword = $$v
                  },
                  expression: "rePassword"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "button-lg-red mt70",
              on: {
                click: function($event) {
                  return _vm.goFinish()
                }
              }
            },
            [_c("a", { attrs: { href: "#" } }, [_vm._v("立即注册")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentStep === 3,
              expression: "currentStep===3"
            }
          ],
          staticClass: "mt80",
          attrs: { id: "step3" }
        },
        [
          _c(
            "div",
            { staticClass: "tips" },
            [
              _c("v-icon", { attrs: { name: "check-circle" } }),
              _vm._v(" "),
              _c("div", { staticClass: "mt20" }, [_vm._v("您的注册已成功")]),
              _vm._v(" "),
              _c(
                "router-link",
                { staticClass: "smallTxt", attrs: { to: "/" } },
                [_vm._v("点击这里回到首页")]
              )
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }