<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
  <div class="mobile" :class="[!tangleSearchModal ? 'close' : '']">
    <MobileHeader @close="close()"></MobileHeader>
    <SearchByBike v-show="currentSearch === 0"
                  :searchCallBack="close"
                  :linkCallBack="showSearchByName"
    />
    <SearchByName  v-show="currentSearch === 1"
                   :searchCallBack="close"
                   :linkCallBack="showSearchByBike"
    />
  </div>
</template>

<script>
import MobileHeader from './public/HeaderBlack.vue'
import SearchByBike from  '../../wechat/SearchByBike.vue'
import SearchByName from  '../../wechat/SearchByName.vue'

export default {
  components: {
    MobileHeader,
    SearchByBike,
    SearchByName,
  },
  data: function() {
    return {
      currentSearch: 0, // 0-bike, 1-name
      tangleSearchModal: false,
    }
  },
  methods: {
    close: function() {
      this.tangleSearchModal = false;
      this.$emit('tangle');
    },
    handleParentClick: function(st) {
      this.tangleSearchModal =  st
    },
    showSearchByName: function() {
      this.currentSearch = 1;
    },
    showSearchByBike: function() {
      this.currentSearch = 0;
    }
  },
}
</script>

<style scoped lang="scss">
  .mobile {
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:#FAFAFA;
    z-index:9;
  }
  .close {
    display:none;
  }
  .mt-3 {
    width:94%;
    margin: 0 auto;
  }
  .icon-search {
    color: #9e9e9e;
    font-size:1.8rem;
  }
  .icon-search:active {
    color: #666
  }
</style>
