// Vue插件：对API请求进行分装
import Vue from 'vue'

import app from '../main.js'

const axios = require('axios');

var ApiHelper = {

  install: function (Vue, options) {
    Vue.api = Vue.prototype.$api = ApiHelper;
  },

  get: function (url, config) {
    if(typeof url === 'undefined' || url === ''){
      return new Promise(function(resolve, reject) {
      });
    }
    return new Promise(function(resolve, reject) {
      axios.get(url, config)
      .then((resp) => {
        if (resp.data.code === 401) {
          app.$modal.show('userLoginModal',{})
          app.$toast.error('请您先登录，再尝试');
          return;
        }
        resolve(resp.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      .then(() => {});
    });
  },

  post: function (url, data, config) {
    return new Promise(function(resolve, reject) {
      axios.post(url, data, config)
      .then((resp) => {
        if (resp.data.code === 401) {
          app.$modal.show('userLoginModal',{})
          app.$toast.error('请您先登录，再尝试');
          return;
        }
        resolve(resp.data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
    });
  },
};

export default ApiHelper;
