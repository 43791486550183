import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted);

const millisec = 2000;

const Toast = {
  install() {
    Vue.prototype.$toast = Toast;
  },
  success(msg) {
    Vue.toasted.success(msg);
    this.clear();
  },
  error(msg) {
    Vue.toasted.error(msg);
    this.clear();
  },
  info(msg) {
    Vue.toasted.info(msg);
    this.clear();
  },
  clear() {
    setTimeout(() => {
      Vue.toasted.clear();
    }, millisec);
  },
};

export default Toast;
