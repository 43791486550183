<template>
  <modal :name="name" :width="550" :height="510">
    <div class="modal-container">
      <div class="modal-title">
        <h2 class="en">LOGIN</h2>
        <h2 class="ch">登录</h2>
      </div>
      <div class="modal-body">
        <b-form-input type="text" placeholder="手机号" v-model="username"></b-form-input>
        <b-form-input type="password" placeholder="密码" v-model="password"></b-form-input>
        <router-link class="forget-password" target="_blank" :to="{name: 'forgetPassword'}">忘记密码?</router-link>

        <div class="modal-btn btn-red" @click="login()">
          <a href="#" >登录</a>
        </div>
        <div class="split">
          <div class="line"></div>
          <span>OR</span>
          <div class="line"></div>
        </div>
        <div class="other">
          <a class="wechat-login" @click="goWechatLogin()">
            <a class=logo href="#"></a>
            <a class="wechatStr" href="#">微信登录</a>
          </a>
          <router-link class="regist" :to="{name: 'register'}">
            注册
          </router-link>
        </div>
      </div>

      <div class="modal_close">
        <a href="#" @click="hide"><v-icon class="close" name="times"/></a>
      </div>
    </div>
  </modal>
</template>

<script>
  export default {
    props: ['modalName'],
    data() {
      return {
        name: this.modalName,
        username:null,
        password:null,
        isLoginClick: false,
        nickName:'',
        phone:''
      }
    },
    methods: {
      hide () {
        this.$modal.hide(this.name);
      },
      goRegister() {
        this.$router.push({name: 'register'})
      },
      goWechatLogin() {
        this.$api.get(`/get_wechat_login_url`)
          .then(res=> {
            if(res.code === 0) {
              window.location.href = res.data.url;
            }
          })
      },
      login() {
        if(this.username && this.password && !this.isLoginClick) {
          this.isLoginClick = true;
          let self = this;
          this.$api.post(`/login`, {phone: this.username, password: this.password})
            .then(data => {
              self.isLoginClick = false;
              if(data.code !== 0) {
                this.$toast.error(data.msg);
                return;
              }
              // set cookie 'phone' expire day = 30day
              // let mobile = self.username.replace(/(\d{3})\d+(\d{4})/, '$1****$2');
              // this.$cookie.setCookie('phone', mobile, 30);

              this.$emit('loginCallBack');
              this.$modal.hide(this.name);

              if(this.$route.matched[0].path === '/account') {
                this.$router.push({name: 'index'})
              }
            }, (err) => {
              this.$toast.error('网络错误，请稍后重试');
              self.isLoginClick = false;
            });
        }
      }
    }
  }
</script>

<style scoped>
  .modal-container {
    padding:56px 100px 40px;
    position: relative;
  }
  .modal-title{
    .en {font-size:4rem; font-weight:500; font-family:'teko'}
    .ch {font-size:2.4rem;}
  }
  .modal-body {
    padding: 20px 0 0 0;
    a:hover{text-decoration: underline}
    input {border: 1px solid #ccc; height:44px; width: 100%; font-size:1.4rem; margin:0 0 12px;}
    .forget-password {font-size:1.2rem; color: #000; text-align:right; display:block; margin: 12px 0; cursor: pointer}
    .forget-password:hover {color: #000; }
    .split {
      display:flex; margin-top:40px;
      .line {width: 40%; border-top:1px solid #000; flex:1}
      span {display: block; color: #000; font-size:1.8rem; font-family:'teko'; width:40px; margin-top:-12px; text-align:center}
    }
  }
  .modal-btn {
    height:44px; text-align:center; cursor:pointer;
    a {color: white; line-height:44px; font-size:1.6rem; font-weight:600;}
  }
  .modal-btn:hover {
    text-decoration: underline;
  }
  .modal-btn:active {
    color: #ccc; opacity: 0.6
  }
  .btn-red {
    background-color:#ec2f00;
  }
  .btn-grey {
    background-color: #000
  }
  .mt8 {
    margin-top:8px;
  }
  .modal_close a{ color: black}
  .modal_close {position:absolute; top:18px; right:20px; width:50px; height:50px;}
  .modal_close .close{font-size:14px;}
  .other {
    display:flex;
    align-items:center;
    .wechat-login {
      flex:2; display:flex; align-items: center;
      .logo {
        width:40px;
        height:40px;
        background:url('https://cdn.mobag.cn/c9d67a3e7ff2334d094c9ddee298d065') 0 0 no-repeat;
        background-size:contain;
        border-radius:50%;
        display:block
      }
      .wechatStr {margin-left:20px; align-items:left; font-size:1.4rem}
    }
    .regist{
      width:140px; flex:1; justify-content: center; height:44px; background-color:black;
      display:flex;align-items: center;color:white; font-size:1.6rem; font-weight:600;
    }
    .flex1{flex:1}
    .regist:active{ opacity: 0.7;}
  }
</style>

