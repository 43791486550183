var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "introduction-bg" }, [
      _c(
        "div",
        { staticClass: "dialog-bg" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.$t("about.aboutUs.infoList"), function(text) {
            return _c("p", [_vm._v(_vm._s(text))])
          }),
          _vm._v(" "),
          _c(
            "p",
            _vm._l(_vm.$t("about.aboutUs.vision"), function(text) {
              return _c("b", [_vm._v(_vm._s(text)), _c("br")])
            }),
            0
          ),
          _vm._v(" "),
          _c("p", { staticClass: "right light-grey font18" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("about.aboutUs.signature")) +
                "\n      "
            )
          ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "placeholder" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "bar" }),
      _vm._v(" "),
      _c("span", { staticClass: "en" }, [_vm._v("About Us")]),
      _vm._v(" "),
      _c("span", { staticClass: "ch" }, [_vm._v("关于我们")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }