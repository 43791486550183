import { render, staticRenderFns } from "./ProductDetail.vue?vue&type=template&id=1903850c&scoped=true&"
import script from "./ProductDetail.vue?vue&type=script&lang=js&"
export * from "./ProductDetail.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetail.vue?vue&type=style&index=0&id=1903850c&scoped=true&lang=scss&"
import style1 from "./ProductDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1903850c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangyu/Projects/Mobag/mobag/mobag_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1903850c')) {
      api.createRecord('1903850c', component.options)
    } else {
      api.reload('1903850c', component.options)
    }
    module.hot.accept("./ProductDetail.vue?vue&type=template&id=1903850c&scoped=true&", function () {
      api.rerender('1903850c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/product/ProductDetail.vue"
export default component.exports