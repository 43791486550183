import { render, staticRenderFns } from "./ProductItem.vue?vue&type=template&id=321a24e7&scoped=true&xmlns%3Av-bind=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./ProductItem.vue?vue&type=script&lang=js&"
export * from "./ProductItem.vue?vue&type=script&lang=js&"
import style0 from "./ProductItem.vue?vue&type=style&index=0&id=321a24e7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321a24e7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wangyu/Projects/Mobag/mobag/mobag_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('321a24e7')) {
      api.createRecord('321a24e7', component.options)
    } else {
      api.reload('321a24e7', component.options)
    }
    module.hot.accept("./ProductItem.vue?vue&type=template&id=321a24e7&scoped=true&xmlns%3Av-bind=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&", function () {
      api.rerender('321a24e7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/web/ProductItem.vue"
export default component.exports