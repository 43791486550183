<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
    <div class="item-container">
      <router-link target="_blank" :to="{ name: 'productDetail', params: {id: product.id} }">
        <div class="pic">
          <img :src="pic.url+'?imageView2/2/w/300'">
          <img src="../../images/play.png" v-if="hasVideo" class="play-icon">
        </div>
        <div class="item-tag">
          <div class="tag-new" v-show="product.traitIdList.indexOf(1) != -1">
            <span class="new" >现货</span>
          </div>
          <div class="tag-hot" v-show="product.traitIdList.indexOf(2) != -1 && product.traitIdList.indexOf(1) != -1">
            <span class="hot" >特价</span>
          </div>
        </div>
      </router-link>

      <div class="desc">
          <router-link target="_blank" :to="{ name: 'productDetail', params: {id: product.id} }">
            <span v-if="product.chineseTitle">{{ product.chineseTitle }}</span>
            <span v-else>{{ product.title }}</span>
          </router-link>
        </div>
<!--        <div class="price">-->
<!--          <span>-->
<!--            ￥{{ (product.currentPrice/100.0).toFixed(2) }}-->
<!--            &lt;!&ndash;<span v-show="product.currentPrice < product.originalPrice">￥{{ (product.originalPrice/100.0).toFixed(2) }}</span>&ndash;&gt;-->
<!--          </span>-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
  props: {
    product: Object,
  },
  mounted() {
    // let a = this.product.traitIdList
  },
  data: function() {
    return {
    }
  },
  computed: {
    // 返回第一个图片类型
    pic() {
      return this.product.headImgList.find(item => item.kind == 1) || {url:""};
    },
    hasVideo() {
      return this.product.headImgList.some(item => item.kind == 0);
    }
  }
}
</script>

<style scoped lang="scss">
  .item-container {
    width:100%; border:1px solid #ececec; margin: 0; padding:30px 15px; background-color:#fff;
    .pic {
      width: 150px;
      height: 150px;
      margin: 20px auto 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
      }
      .play-icon {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -25px auto 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .item-tag {
      position:absolute;
      left:7px;
      top:18px;
    }
    .tag-new, .tag-hot {
      border-right: 10px solid transparent;
      border-top: solid 30px #84c44f;
      width:60px;
      height:30px;
      margin-bottom:10px;
      span{color:#fff; font-size:1.4rem; margin: -25px 10px; display:block}
    }
    .tag-hot {border-top: solid 30px #ec2f00;}
    .desc{
      height:40px;
      span{
        text-overflow:ellipsis;
        overflow:hidden;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        font-size:1.4rem;
        color: #000;
        padding: 0 15px;
      }
    }
    .price{
      span {color:#ec2f00; font-size:2.8rem; padding: 0 8px;}
    }
  }
  @media screen and(max-width: 767px){
    .item-container {
      padding: 12px;
      .pic {
        max-width: 150px;
        max-height: 150px;
        width:100%;
        margin: 10px auto;
      }
      .desc{
        height:34px;
        span{
          font-size:1.2rem;
          padding: 0;
        }
      }
      .price{
        span {font-size:2rem; margin-top:4px; padding:0;}
      }
    }
  }
  @media screen and(max-width: 576px) {
    .item-container {
      margin:0;
      .item-tag{top:2px; left:2px}
    }
  }
</style>
