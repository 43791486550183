<template>
  <product-list :props="props" />
</template>

<script>
import ProductList from './ProductList.vue'
export default {
  data: function() {
    return {
      props: {
        productUrl: '',
        tagType: null,
        searchInfo: null,
      }
    }
  },
  components: {
    'product-list': ProductList,
  },
  computed: {
    tags() {
      return this.$route.query.tags || ''
    },
    brands() {
      return this.$route.query.brands || ''
    },
    tagType() {
      return this.$route.params.tagType
    }
  },
  created: function() {
    this.updateProps(this.tagType)
  },
  beforeRouteUpdate: function(to, from, next) {
    this.updateProps(to.params.tagType)
    next();
  },
  methods: {
    updateProps(tagType) {
      this.props = {
        productUrl: `/query_product_by_tag_type?tag_type=${tagType}`,
        tagUrl: `/query_tag_list_by_tag_type?tag_type=${tagType}`,
        brandUrl: `/query_product_brand_by_tag_type?tag_type=${tagType}`,
        tagType: tagType,
        searchInfo: null,
        tags: this.tags,
        brands: this.brands
      }
    }
  }
}
</script>

<style scoped>
</style>
