<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">Purchase</span>
          <span class="ch">如何购买</span>
        </div>
        <div>
          <p>申请成为经销商：<a href="https://dealer.mobag.cn" target="_blank">经销商购买</a></p>
          <p v-for="text in $t('about.howToBuy')"><b>{{ text }}</b></p>
        </div>
        <img :src="sellQrCode" style="width: 12rem" alt="">
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import {
  getRandomSelQrCode
} from '../../common/Util';

export default {
  data: function() {
    return {
      sellQrCode: getRandomSelQrCode(),
    }
  },
  created() {
    window.document.title = '摩燃-如何购买';
  }
}
</script>

<style scoped>
  .title {
    left: -190px;
  }
  .btn-buy{
		width: 146px;
		height: 44px;
		background-color: #ec2f00;
		color: #fff;
		line-height:1.6rem;
		text-align:center;
		border:0;
		margin-top:40px;
	}
  @media (max-width:900px) {
    .introduction-bg {
    .title {
      left:0;
      top: -100px;
    }
  }
  }
</style>
