<template>
  <div>
    <div class="introduction-bg">
      <div class="dialog-bg">
        <div class="title">
          <div class="bar"></div>
          <span class="en">Q & A</span>
          <span class="ch">常见问题</span>
        </div>

        <p v-for="qa in $t('about.faq.qaList')">
          <b>{{ qa[0] }}</b>
          <br>
          {{ qa[1] }}
        </p>

        <p>
          <b>{{ $t("about.faq.purchaseAssistant") }}</b><br>
          <img :src="sellQrCode" style="width: 12rem" alt="">
        </p>

        <p>
          <b>{{ $t("about.faq.wechat") }}</b><br>
          <img src="https://cdn.mobag.cn/barcode_search.png" style="width: 40rem" alt="">
        </p>
      </div>
    </div>
    <div class="placeholder"></div>
  </div>
</template>

<script>
import {
  getRandomSelQrCode
} from '../../common/Util';

export default {
  data: function() {
    return {
      sellQrCode: getRandomSelQrCode(),
    }
  },
  created() {
    window.document.title = '摩燃-常见问题';
  }
}
</script>

<style scoped>
  .title {
    left: -125px;
  }
  .placeholder {height:92%;}
  @media (max-width:900px) {
    .introduction-bg {
      .title {
        left:0;
        top: -100px;
      }
    }
  }
</style>
